import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes'
import _ from 'lodash'
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import { Tabs, Tab } from 'react-bootstrap'
import WidgetSettingsTab from './widgetSettings';
import StatsSettings from './statsSettings';

const WidgetSettings = ({ show, handleClose, title, sortedWidgets, hiddenWidgets, removeUnAllowedWidgets, layout, setLayout, onLayoutChange, sortedStats, hiddenStats, enquiryStatus, dealersettings, hideStats }) => {
	const [localSortedWidgets, setLocalSortedWidgets] = useState(sortedWidgets);
	const [localHiddenWidgets, setLocalHiddenWidgets] = useState(hiddenWidgets);
	const [localSortedStats, setLocalSortedStats] = useState(sortedStats);
	const [localHiddenStats, setLocalHiddenStats] = useState(hiddenStats);
	const [saveLoader, setSaveLoader] = useState(false);


	const handleAdd = (value) => {
		setLocalHiddenWidgets(localHiddenWidgets.filter(a => a !== value))
		setLocalSortedWidgets([value, ...localSortedWidgets])
	}

	const handleRemove = (value) => {
		setLocalSortedWidgets(localSortedWidgets.filter(a => a !== value))
		setLocalHiddenWidgets([value, ...localHiddenWidgets])
	}

	const onSave = async () => {
		setSaveLoader(true)
		const dashboardSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`)
		await dashboardSettingsRef.set({
			hiddenWidgets: localHiddenWidgets,
			sortedWidgets: localSortedWidgets
		}, { merge: true })

		const statsSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${dealersettings.client.id}`)
		await statsSettingsRef.set({
			hiddenStats: localHiddenStats,
			sortedStats: localSortedStats
		}, { merge: true })

		onLayoutChange(layout, localSortedWidgets, localHiddenWidgets)

		toast.notify('Dashboard Settings updated successfully', {
			duration: 2000
		})
		handleClose()
		setSaveLoader(false)
	}

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		if (
			result.destination.droppableId === result.source.droppableId
		) {
			return;
		}
		if (result.destination.droppableId === 'active-widget') {
			setLocalHiddenWidgets(localHiddenWidgets.filter(a => a !== result.draggableId))
			setLocalSortedWidgets([result.draggableId, ...localSortedWidgets])
		}
		else {
			setLocalSortedWidgets(localSortedWidgets.filter(a => a !== result.draggableId))
			setLocalHiddenWidgets([result.draggableId, ...localHiddenWidgets])
		}

	}
	const onDragEndStats = (result) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			if (destination.droppableId === 'active-stats') {
				const sourceItems = localHiddenStats;
				const destItems = localSortedStats;
				const [removed] = sourceItems.splice(source.index, 1);
				destItems.splice(destination.index, 0, removed);
				setLocalHiddenStats(sourceItems)
				setLocalSortedStats(destItems)
			}
			else {
				setLocalSortedStats(localSortedStats.filter(a => a !== result.draggableId))
				setLocalHiddenStats([result.draggableId, ...localHiddenStats])
			}

		} else {
			const copiedItems = destination.droppableId === 'active-stats' ? localSortedStats : localHiddenStats;
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			if (destination.droppableId === 'active-stats') {
				setLocalSortedStats(copiedItems)
			} else {
				setLocalHiddenStats(copiedItems)
			}
		}

		// if (!result.destination) {
		// 	return;
		// }

		// if (
		// 	result.destination.droppableId === result.source.droppableId
		// ) {
		// 	const [removed] = _activeLookups.splice(result.source.index, 1);
		// 	_activeLookups.splice(result.destination.index, 0, removed);
		// }
		// if (result.destination.droppableId === 'active-stats') {
		// 	const _stats = result.destination.droppableId !== result.source.droppableId ? [result.draggableId, ...localSortedStats] : localSortedStats;
		// 	const [removed] = _stats.splice(result.source.index, 1);
		// 	_stats.splice(result.destination.index, 0, removed);
		// 	setLocalHiddenStats(localHiddenStats.filter(a => a !== result.draggableId))
		// 	setLocalSortedStats(_stats)
		// }
		// else {
		// 	setLocalSortedStats(localSortedStats.filter(a => a !== result.draggableId))
		// 	setLocalHiddenStats([result.draggableId, ...localHiddenStats])
		// }

	}

	const handleAddStats = (value) => {
		setLocalHiddenStats(localHiddenStats.filter(a => a !== value))
		setLocalSortedStats([value, ...localSortedStats])
	}

	const handleRemoveStats = (value) => {
		setLocalSortedStats(localSortedStats.filter(a => a !== value))
		setLocalHiddenStats([value, ...localHiddenStats])
	}

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				enforceFocus={false}
				dialogClassName='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dash-widget-setings'
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='modal-title'>
							<Translate text={title} />
						</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<div className='dashsetings-wrap'>
								<div className='settings-tab-wrap tabs'>
									<div className="tabs-wraper">
										<nav>
											<Tabs defaultActiveKey={hideStats ? "widgetSettings" : "statsSettings"} transition={false} className="nav-fill">
												{
													hideStats ? (<></>) : (
														<Tab eventKey="statsSettings" title={CommonHelper.showLocale({ dealersettings }, "Pipeline Stats Settings")}>
															<StatsSettings
																localSortedStats={localSortedStats}
																localHiddenStats={localHiddenStats}
																handleAdd={handleAddStats}
																handleRemove={handleRemoveStats}
																onDragEnd={onDragEndStats}
																enquiryStatus={enquiryStatus}
															/>
														</Tab>
													)
												}
												<Tab eventKey="widgetSettings" title={CommonHelper.showLocale({ dealersettings }, "Widget Settings")}>
													<WidgetSettingsTab
														localSortedWidgets={localSortedWidgets}
														localHiddenWidgets={localHiddenWidgets}
														removeUnAllowedWidgets={removeUnAllowedWidgets}
														handleAdd={handleAdd}
														handleRemove={handleRemove}
														onDragEnd={onDragEnd}
													/>
												</Tab>
											</Tabs>
										</nav>
									</div>

								</div>
							</div>
						</div>

					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<button
						type='button'
						className='btn btn-primary float-right ml-2 '
						aria-label='Save'
						onClick={onSave}
					>
						{
							saveLoader ?
								(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
								: (<></>)
						}
						<Translate text={'save'} />
					</button>
					<button
						type='button'
						className='btn btn-default float-right ml-2 '
						aria-label='Close'
						onClick={handleClose}
					>
						{' '}
						<Translate text={'Close'} />
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default WidgetSettings;
