/** LIBRARIES */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
//import { Mentions } from 'antd';
//import 'antd/dist/antd.css';
import Translate from '../../constants/translate';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import axios from 'axios';

/** COMPONENTS */
import {
	ReactTimePicker,
	ReactMultiSelect,
	ReactSelect,
	PopUpModal,
	AntDatePicker,
	ReactDatePicker
} from '../../components';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';
import Contacts from '../contacts/list/index';
import PopoverEnquiryList from '../pipeline/popoverlist';
import Stocks from '../stock/list/index';
import TradeinList from '../tradeinplus/list/index'
import ServiceList from '../service/contactList/index'
import AddStock from '../stock/add';
import ActivityTag from '../pipeline/details/activityTag'
import AddActivityLog from './addLog';
import ViewActivityLog from './viewLog';
import EmailPreview from "../mail/emailPreview";

import ViewNotesHistory from './viewNotesHistory';
/** VIEW-MODELS */
import { activitylogTypes, objActivitylog, enquiryBasicTDVM } from '../pipeline/viewModel';
import { tipBasicDetailsVM } from '../tradeinplus/viewModel'
import { objServiceBasicVM } from '../service/viewModel'
// import MentionTextArea from '../../components/mentionTextArea';
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import ActivityStatus from './actvityStatusModal';
import { enqUnActionStatus, reminderOptions } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

import ContactLink from './link/contact'
import EnquiryLink from './link/enquiry'
import ServiceLink from './link/service'
import TradeinProLink from './link/tradeinpro';
import StockLink from './link/stock'
import enquiryHelper from '../../services/enquiryHelper';

const parseScheduleActivity = (log, clientUsers, enquiry, dealersettings) => {
	const objData = Object.assign({}, objActivitylog);
	for (let [key, value] of Object.entries(log)) {
		if (objData.hasOwnProperty(key) && value)
			objData[key] = value;
	}
	let parsedLog = { ...objData };
	parsedLog.documentID = firestoreDB(dealersettings).firestore().collection('activites').doc().id;
	parsedLog.isDone = false;
	parsedLog.notes = '';
	// parsedLog.title = '';
	parsedLog.owner = (enquiry && enquiry.owner) ? enquiry.owner : localStorage.uid;
	parsedLog.addedBy = localStorage.uid;
	parsedLog.addedByName = CommonHelper.getUserNamebyId(
		clientUsers,
		localStorage.uid,
	);
	parsedLog.teammates = [];
	parsedLog.teams = [];
	parsedLog.addedDate = window.firebase.firestore.Timestamp.now();

	let _minutes = ['call'].includes(parsedLog.subType) ? 15 : 60;
	parsedLog.startDate = enquiryHelper.mapStartDate(_minutes, dealersettings)
	parsedLog.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(parsedLog.startDate.seconds).add(_minutes, 'minutes')._d);
	parsedLog.endDate = enquiryHelper.mapEndDate(parsedLog.startDate, parsedLog.endDate, dealersettings)
	return parsedLog;
}

const parseNewScheduleActivity = (log, props) => {
	//console.log('parseNewScheduleActivity', log)
	const { isNewActivity, dealersettings } = props;
	if (isNewActivity && log) {
		let parsedLog = { ...log };
		let _minutes = ['call'].includes(parsedLog.subType) ? 15 : 60;
		parsedLog.startDate = enquiryHelper.mapStartDate(_minutes, dealersettings)
		parsedLog.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(parsedLog.startDate.seconds).add(_minutes, 'minutes')._d);
		parsedLog.endDate = enquiryHelper.mapEndDate(parsedLog.startDate, parsedLog.endDate, dealersettings)
		return parsedLog;
	}
	else {
		return log;
	}
}


const AddActivity = props => {
	const [teamMentions, setTeamMentions] = useState(null);
	const [activeTab, setActivetab] = useState(
		props.activeTab
			? props.activeTab
			: props.activityLog
				? props.activityLog.subType
				: 'appointment',
	);
	// users declared here to be passed below to avoid error of react hooks
	const users = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
	const [activityLog, setActivitylog] = useState(props.isScheduleActivity ? parseScheduleActivity(props.activityLog, users, (props.enquiry ? props.enquiry : (props.tradeinPro ? props.tradeinPro : props.service)), props.dealersettings) : parseNewScheduleActivity(props.activityLog, props));
	const [activityOldLog, setActivityOldlog] = useState(props.isScheduleActivity ? parseScheduleActivity(props.activityLog, users, (props.enquiry ? props.enquiry : (props.tradeinPro ? props.tradeinPro : props.service)), props.dealersettings) : props.activityLog);
	//const [userOptions, setUseroptions] = useState([]);
	const [allUsers, setAllusers] = useState([]);
	//const [allContacts, setContacts] = useState([])
	const [contactID, setContactID] = useState(
		!_.isEmpty(props.contact)
			? props.contact.documentID
			: !_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact)
				? props.enquiry.contact.documentID
				: !_.isEmpty(props.tradeinPro) && !_.isEmpty(props.tradeinPro.contact)
					? props.tradeinPro.contact.documentID
					: !_.isEmpty(props.service) && !_.isEmpty(props.service.contact)
						? props.service.contact.documentID
						: '',
	);
	const [stockID, setStockID] = useState(
		!_.isEmpty(props.stock) ? props.stock.documentID : '',
	);
	//const [enquiries, setEnquiries] = useState([])
	const [selectedEnquiry, setEnquiry] = useState(
		!_.isEmpty(props.enquiry) ? props.enquiry : null,
	);
	const [selectedTIP, setTIP] = useState(
		!_.isEmpty(props.tradeinPro) ? props.tradeinPro : null,
	);
	const [selectedService, setService] = useState(
		!_.isEmpty(props.service) ? props.service : null,
	);
	const [selectedCustomer, setCustomer] = useState(
		!_.isEmpty(props.contact) ? props.contact : null,
	);
	const [selectedStock, setStock] = useState(
		!_.isEmpty(props.stock) ? props.stock : null,
	);
	const inputRef = useRef(null);
	const [loader, setLoader] = useState(false);
	const [deleteLoader, setDeleteloader] = useState(false);
	const [contactPopover, setContactPopover] = useState({
		show: false,
		target: null,
	});
	const [enquiryPopover, setEnquiryPopover] = useState({
		show: false,
		target: null,
	});
	const [tradeinProPopover, setTIPPopover] = useState({
		show: false,
		target: null,
	});
	const [servicePopover, setServicePopover] = useState({
		show: false,
		target: null,
	});
	const [stockPopover, setStockPopover] = useState({
		show: false,
		target: null,
	});
	const [stockModel, setStockModel] = useState({
		show: false,
		ID: '',
		title: '',
		clsActive: '',
	});
	const [showOutcomeModal, setShowOutcomeModal] = useState({
		show: false,
		clsActive: '',
		isMandatory: false,
		data: {}
	});
	const [testdriveSlots, setTestDriveSlots] = useState([]);
	const [mentions, setMentions] = useState([]);
	const [slotLoader, setSlotLoader] = useState(false);
	const [oldactivityLog, setOldActivitylog] = useState();
	const [origins, setOrigins] = useState([])
	//const [allTeams, setAllTeams] = useState([])
	const [allTeamUsers, setAllTeamUsers] = useState([])

	const [scheduledActivities, setScheduledActivities] = useState([])
	const [linkedActivities, setLinkedActivities] = useState([])
	const textareaRef = useRef(null);
	const [completedTab, setCompltedtab] = useState({ type: 'all', subtype: 'all' })
	const [showScheduledActivities, setShowScheduled] = useState(false);
	const [linkLogModel, setLinkLogModel] = useState({
		show: false,
		ID: '',
		title: '',
		clsActive: '',
	});
	const [showPreviewModel, setPreviewModel] = useState(false)
	const [emailLog, setEmaillog] = useState(null)
	const [popheader, setPopheader] = useState()
	const [showLogModel, setShowLogModel] = useState(false)
	const [activityLogNotes, setActivityLogNotes] = useState('')
	const [showNotesHistoryModel, setShowNotesHistoryModel] = useState(false)
	const [notesHistory, setNotesHistory] = useState([])
	const [errorNotes, setErrorNotes] = useState('')

	const [activityTypes, setActivityTypes] = useState(activitylogTypes);
	const [rosterMsg, setRosterMsg] = useState(null)
	const [doneloader, setDoneLoader] = useState(false);

	const history = useHistory();
	const mentionsData = props.clientUsers.filter(a => a.id !== localStorage.uid && a.active === true).map(clientUser => {
		return {
			name: `@${clientUser.name}`,
			id: clientUser.id,
			avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
		};
	});

	const isOutlookEnabled =
		!_.isEmpty(props.dealersettings) &&
			!_.isEmpty(props.dealersettings.outlookCalendar) &&
			props.dealersettings.outlookCalendar.refreshToken ? true : false

	const isGoogleCalendarEnabled =
		!_.isEmpty(props.dealersettings) &&
			!_.isEmpty(props.dealersettings.googleCalendar) &&
			props.dealersettings.googleCalendar.refreshToken ? true : false

	const mandScheduleNextActivity = (
		(props.isScheduleActivity && props.dealersettings?.rolePermissions?.permissions?.mandScheduleNextActivity) ||
		(props.isScheduleActivity && props.dealersettings?.rolePermissions?.permissions?.mandScheduleNextActivityOpen) ||
		(props.isNewLeadActivity && props.dealersettings?.client?.clientSettings?.isNextActivityMandatoryNE)) ? true : false

	props.dealersettings.client.teams.forEach(team => {
		mentionsData.push({
			name: `@${team.name}`,
			id: team.user_ids.join(','),
			avatar: _images.group
		})
	})

	const apiURL =
		process.env.NODE_ENV === 'development'
			? 'http://localhost:5001/ams-pro-qa/us-central1/'
			: props.dealersettings.apiURL;

	const enableCustShowNoShow = props.dealersettings?.client?.clientSettings?.enableCustShowNoShow ? true : false;
	const workingHours = (props.dealersettings?.client?.clientSettings?.scheduleActWithInWHrs && props.dealersettings?.client?.workingHours) ? props.dealersettings.client.workingHours : null;
	// const _userRoles = (enableCustShowNoShow &&
	// 	props.dealersettings && props.dealersettings.roleID &&
	// 	props.dealersettings.client && props.dealersettings.client.roles &&
	// 	props.dealersettings.client.roles.filter(e => e.documentID === props.dealersettings.roleID)[0]);

	// const isInboundAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e === 'LeadsBucket') ? true : false;

	useEffect(() => {
		if (_.isEmpty(props.activityLog) || props.activityLog.type !== CommonHelper.getActivityType('activity', props.originType))
			return

		setOldActivitylog(props.isScheduleActivity ? {} : props.activityLog)

	}, [])

	useEffect(() => {
		setActivityOldlog(props.isScheduleActivity ? parseScheduleActivity(props.activityLog, users, (props.enquiry ? props.enquiry : (props.tradeinPro ? props.tradeinPro : props.service)), props.dealersettings) : (Object.assign({}, props.activityLog ? { ...props.activityLog, isDone: props.activityLog.isFromComplete ? !props.activityLog.isDone : props.activityLog.isDone } : {})))

	}, [])

	useEffect(() => {
		if (!activityLog && _.isEmpty(props.activityID)) {

			const { dealersettings } = props;
			let _minutes = ['call'].includes(activeTab) ? 15 : 60;
			let _actName = activityTypes.filter(e => e.type === activeTab)[0] ? activityTypes.filter(e => e.type === activeTab)[0].name : null;
			if (_.isEmpty(_actName)) {
				let _settings = dealersettings && dealersettings.client && dealersettings.client.settings;
				_actName = CommonHelper.getNameByValue(_settings && _settings.activityTypes, activeTab, '')
			}

			let newlogVM = Object.assign({}, objActivitylog);
			newlogVM.type = CommonHelper.getActivityType('activity', props.originType);
			newlogVM.subType = activeTab;
			newlogVM.title = _actName;
			newlogVM.owner = localStorage.uid;
			newlogVM.addedBy = localStorage.uid;
			newlogVM.modifiedBy = localStorage.uid;
			newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
			newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();

			if (props.enquiry) {
				newlogVM.enquiryID = props.enquiry.documentID;
				newlogVM.contactID = (props.enquiry.contact?.documentID || '');
			}
			else if (props.tradeinPro) {
				newlogVM.tradeinProID = props.tradeinPro.documentID;
				newlogVM.contactID = (props.tradeinPro.contact?.documentID || '');
			}
			else if (props.service) {
				newlogVM.serviceID = props.service.documentID;
				newlogVM.contactID = (props.service.contact?.documentID || '');
			} else if (props.contact) {
				newlogVM.enquiryID = null;
				newlogVM.contactID = props.contact.documentID;
			}

			newlogVM.stockID = (props.stock?.documentID || null);
			//newlogVM.clientID = props.dealersettings.client.id;
			newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
			if (props.slotDate) {
				newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment(props.slotDate)._d,);
				//newlogVM.startDate = enquiryHelper.mapStartDate(_minutes, dealersettings, window.firebase.firestore.Timestamp.fromDate(moment(props.slotDate)._d))
				newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate.seconds).add(_minutes, 'minutes')._d);
				//newlogVM.endDate = enquiryHelper.mapEndDate(newlogVM.startDate, newlogVM.endDate, dealersettings);
			} else {
				newlogVM.startDate = enquiryHelper.mapStartDate(_minutes, dealersettings)
				newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate.seconds).add(_minutes, 'minutes')._d);
				newlogVM.endDate = enquiryHelper.mapEndDate(newlogVM.startDate, newlogVM.endDate, dealersettings);
			}
			newlogVM.addedByName = CommonHelper.getUserNamebyId(props.clientUsers, localStorage.uid,);
			newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(props.enquiry) ? props.enquiry.clientID
				: !_.isEmpty(props.tradeinPro) ? props.tradeinPro.clientID
					: !_.isEmpty(props.service) ? props.service.clientID
						: null));
			setActivitylog(newlogVM);
			setActivityOldlog(newlogVM);
			//console.log(moment()._d)
			//console.log(moment().format('DD/MM/YYYY hh:mm A'))
		}
	}, []);

	useEffect(() => {
		let options = [];
		if (_.isEmpty(activityLog) || activityLog.clientID === props.dealersettings.client.id) {

			let _selectedUsers = props.clientUsers;
			if (!_.isEmpty(props.roleIDs))
				_selectedUsers = _.filter(_selectedUsers, (v) => _.includes(props.roleIDs, v.roleID));

			_selectedUsers && _selectedUsers.map(rec => {
				return options.push({
					value: rec.id,
					searchlabel: rec.name,
					active: _.isBoolean(rec.active) ? rec.active : true,
					label: (
						<div className='drop-image'>
							<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
							{rec.name}
						</div>
					),
				});
			});
		}
		else if (!_.isEmpty(props.groupUsers)) {
			let _selectedUsers = props.groupUsers;
			if (!_.isEmpty(props.roleIDs))
				_selectedUsers = _.filter(props.groupUsers.filter(a => a.clientID === activityLog.clientID), (v) => _.includes(props.roleIDs, v.roleID));

			_selectedUsers && _selectedUsers.map(rec => {
				return options.push({
					value: rec.id,
					searchlabel: rec.name,
					active: _.isBoolean(rec.active) ? rec.active : true,
					label: (
						<div className='drop-image'>
							<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
							{rec.name}
						</div>
					),
				});
			});
		}
		setAllusers(options);
		// return () => {
		//     window.unSubscribeActivities && window.unSubscribeActivities = null;
		// }
	}, [])

	useEffect(() => {
		if (_.isEmpty(activityLog))
			return

		loadSettings();
	}, [props.dealersettings, activityLog])

	useEffect(() => {
		getActivitybyID(props.activityID);
		loadNoteHistory();
	}, [props.activityID])

	useEffect(() => {
		if (_.isEmpty(activityLog)) {
			return;
		}

		loadData();

	}, [activityLog])

	useEffect(() => {
		if (stockID && !_.isEmpty(activityLog)) {
			//console.log('stockID',stockID )
			setSlotLoader(true);
			window.unSubscribeActivities = firestoreDB(props.dealersettings).firestore().collection('activities')
				.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.unix(activityLog.startDate.seconds).startOf('day')._d,),)
				.where('isDeleted', '==', false)
				.where('isDone', '==', false)
				.where('stockID', '==', stockID)
				.orderBy('startDate', 'desc')
				.get()
				.then(querySnapshot => {
					onCollectionUpdate(querySnapshot);
				});
		}
	}, [stockID, activityLog ? activityLog.startDate : true]);

	useEffect(() => {

		if (_.isEmpty(activityLog)) {
			window.unSubscribeSelectionActivities && window.unSubscribeSelectionActivities();
			return;
		}

		if (activityLog.owner && activityLog.startDate && (showScheduledActivities || props.isNewActivity)) {
			let unSubscribeSelectionActivities = firestoreDB(props.dealersettings).firestore().collection('activities')
				.where('searchUsers', 'array-contains', activityLog.owner)
				//.where('clientID', '==', activityLog.clientID)
				.where('type', '==', CommonHelper.getActivityType('activity', props.originType))
				.where('isDeleted', '==', false)
				.where('isDone', '==', false)
				.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.unix(activityLog.startDate.seconds).startOf('day')._d))
				.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.unix(activityLog.startDate.seconds).endOf('day')._d))
				.orderBy('startDate', 'desc');
			unSubscribeSelectionActivities = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(unSubscribeSelectionActivities, props.dealersettings, activityLog.clientID)
			window.unSubscribeSelectionActivities = unSubscribeSelectionActivities.onSnapshot(querySnapshot => {

				let _activities = [];
				querySnapshot.forEach(function (doc) {
					var logVM = {
						...doc.data(),
						documentID: doc.id,
						startDate: _.isEmpty(doc.data().startDate) ? doc.data().addedDate : doc.data().startDate
					};
					_activities.push(logVM)
				});

				if (!_.isEmpty(_activities)) {
					if (activityLog && activityLog.documentID)
						_activities = _activities.filter(e => e.documentID !== activityLog.documentID).sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });
					else
						_activities = _activities.sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });
				}

				setScheduledActivities(_activities);
			})

			return () => {
				window.unSubscribeSelectionActivities && window.unSubscribeSelectionActivities();
			}

		}

	}, [activityLog ? activityLog.startDate : true, activityLog ? activityLog.owner : true])

	useEffect(() => {
		if (_.isEmpty(activityLog))
			return;
		try {
			CommonHelper.checkUserRoster(props.dealersettings, activityLog, (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), true)
				.then(msg => {
					setRosterMsg(msg);
				})
		}
		catch (err) {
			setRosterMsg(null);
		}

	}, [activityLog ? activityLog.startDate : false, activityLog ? activityLog.owner : false])

	useEffect(() => {
		if (_.isEmpty(activityLog)) {
			return;
		}

		if (_.isEmpty(selectedCustomer))
			return

		let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
			.where('linkActivityID', '==', activityLog.documentID)
			.where('isDeleted', '==', false)

		let linkEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
			.where('activityID', '==', activityLog.documentID)


		var activitiesLinkedCollection = combineLatest(
			collection(refActivitiesData),
			collection(linkEmailsData),
		).pipe(
			map(([activities, emails]) => {
				return [
					_.map(activities, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: _.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate } }),
					_.map(emails, function (obj) { return { ...obj.data(), documentID: obj.id, type: CommonHelper.getActivityType('log', props.originType), subType: 'email', isDone: true, startDate: obj.data().linkedDate, addedDate: obj.data().linkedDate, owner: obj.data().linkedBy, threadID: (obj.data().thread ? obj.data().thread.id : null) } }),
				]
			}))
			.subscribe(async ([activities, emails]) => {
				if (!_.isEmpty(activities))
					activities = activities.sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });

				if (!_.isEmpty(emails))
					emails = emails.sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });


				setLinkedActivities(activities.concat(emails))
			});
		return () => {
			activitiesLinkedCollection && activitiesLinkedCollection.unsubscribe()
		}

	}, [selectedCustomer])

	const handlePreviewEmail = useCallback((emailLog, id, token) => {
		setEmaillog({
			...emailLog,
			documentID: id,
			token: token
		})
		setPopheader(emailLog.subject)
		setPreviewModel(true)
	}, [])

	const loadSettings = () => {
		const { dealersettings } = props;
		const _clientData = activityLog.clientID && dealersettings && dealersettings.group && dealersettings.group.clients[activityLog.clientID]
		let setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

		if (_clientData)
			setttings = dealersettings.group.clients[activityLog.clientID].settings;

		if (setttings) {
			const _origins = [];

			setttings.origins && setttings.origins.forEach(doc => {
				_origins.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name,
				});
			});


			const _teams = [];
			(activityLog.clientID === dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.client.teams && dealersettings.client.teams.forEach(doc => {
				_teams.push({
					value: doc.id,
					type: 'team',
					searchlabel: doc.name,
					user_ids: doc.user_ids ? doc.user_ids : [],
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: (
						<div className='drop-image'>
							<img src={CommonHelper.showUserAvatar('', doc.name)} alt="" width="50" className="img-object-fit" />
							{doc.name}
						</div>
					)
				});
			});

			let _users = [];
			if (!_.isEmpty(activityLog)) {
				if (activityLog.clientID === dealersettings.client.id || localStorage.defaultModule === 'oem') {
					props.clientUsers.filter(e => e.id !== activityLog.owner).map(rec => {
						return _users.push({
							value: rec.id,
							type: 'user',
							searchlabel: rec.name,
							active: _.isBoolean(rec.active) ? rec.active : true,
							label: (
								<div className='drop-image'>
									<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
									{rec.name}
								</div>
							),
						});
					});
				}
				else if (!_.isEmpty(props.groupUsers)) {
					props.groupUsers.filter(e => e.clientID === activityLog.clientID && e.id !== activityLog.owner).map(rec => {
						return _users.push({
							value: rec.id,
							type: 'user',
							searchlabel: rec.name,
							active: _.isBoolean(rec.active) ? rec.active : true,
							label: (
								<div className='drop-image'>
									<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
									{rec.name}
								</div>
							),
						});
					});
				}
			}

			const _activityTypes = [];
			setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
				_activityTypes.push({
					...doc,
					ico: `ico icon-${doc.icon}`,
					name: doc.name,
					type: doc.value
				});
			});

			if (!_.isEmpty(_activityTypes))
				setActivityTypes(_activityTypes);

			//setUseroptions(_users);
			setOrigins(_origins);
			//setAllTeams(_teams);
			setAllTeamUsers([
				{
					label: 'Teams',
					options: _teams
				},
				{
					label: 'Users',
					options: _users
				}
			])
		}
	}

	const loadNoteHistory = () => {
		let _id = props.activityID ? props.activityID : (activityLog && activityLog.documentID ? activityLog.documentID : '');
		if (!_.isEmpty(_id)) {
			let activitiesCollectionnNH = firestoreDB(props.dealersettings)
				.firestore()
				.collection(`activities/${_id}/notesHistory`)
				.onSnapshot(onCollectionHistoryUpdate);

			return () => {
				activitiesCollectionnNH && activitiesCollectionnNH();
			}
		}
	}

	const getActivitybyID = async id => {
		if (!_.isEmpty(id)) {
			const activitySnapshot = await firestoreDB(props.dealersettings)
				.firestore()
				.doc(`activities/${id}`)
				.get();
			if (activitySnapshot.exists) {
				const activityDoc = activitySnapshot.data();
				setActivitylog(activityDoc);
				setActivetab(activityDoc.subType)
				setActivityOldlog(activityDoc);
				setEnquiry(!_.isEmpty(activityDoc.enquiry) ? activityDoc.enquiry : null);
				setTIP(!_.isEmpty(activityDoc.tradeinPro) ? activityDoc.tradeinPro : null);
				setService(!_.isEmpty(activityDoc.service) ? activityDoc.service : null);
				setCustomer(!_.isEmpty(activityDoc.contact) ? activityDoc.contact : null);
				setStock(!_.isEmpty(activityDoc.stock) ? activityDoc.stock : null);
			}
		}
	};

	const loadData = () => {
		if (!_.isEmpty(activityLog) && activityLog.enquiryID && _.isEmpty(activityLog.enquiry)) {
			getEnquirybyID(activityLog.enquiryID);
		}
		else if (!_.isEmpty(activityLog) && activityLog.tradeinProID && _.isEmpty(activityLog.tradeinPro)) {
			getTIPbyID(activityLog.tradeinProID);
		}
		else if (!_.isEmpty(activityLog) && activityLog.serviceID && _.isEmpty(activityLog.service)) {
			getServicebyID(activityLog.serviceID);
		}
		else if (!_.isEmpty(activityLog) && activityLog.contactID && _.isEmpty(activityLog.contact)) {
			getContactbyID(activityLog.contactID);
		}
	}

	const getEnquirybyID = async id => {
		if (!_.isEmpty(id)) {
			const enqSnapshot = await firestoreDB(props.dealersettings)
				.firestore()
				.doc(`enquiries/${id}`)
				.get();
			if (enqSnapshot.exists) {
				const activityDoc = enqSnapshot.data();

				let __ObjEnquiry = Object.assign({}, enquiryBasicTDVM);
				for (let [key, value] of Object.entries(activityDoc)) {
					__ObjEnquiry[key] = value;
				}

				let _log = Object.assign({}, activityLog);
				_log.enquiry = Object.assign({}, !_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedEnquiry);

				if (_.isEmpty(_log.contact)) _log.contact = Object.assign({}, !_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer)

				setActivitylog(_log);

				setEnquiry(!_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedEnquiry);
				setCustomer(!_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer);
			}
		}
	};

	const getTIPbyID = async id => {
		if (!_.isEmpty(id)) {
			const enqSnapshot = await firestoreDB(props.dealersettings).firestore().doc(`tradeinPro/${id}`)
				.get();
			if (enqSnapshot.exists) {
				const activityDoc = enqSnapshot.data();

				let __ObjEnquiry = Object.assign({}, tipBasicDetailsVM);
				for (let [key, value] of Object.entries(activityDoc)) {
					__ObjEnquiry[key] = value;
				}

				let _log = Object.assign({}, activityLog);
				_log.tradeinPro = Object.assign({}, !_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedTIP);

				if (_.isEmpty(_log.contact)) _log.contact = Object.assign({}, !_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer)

				setActivitylog(_log);

				setTIP(!_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedTIP);
				setCustomer(!_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer);
			}
		}
	};

	const getServicebyID = async id => {
		if (!_.isEmpty(id)) {
			const enqSnapshot = await firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${id}`)
				.get();
			if (enqSnapshot.exists) {
				const activityDoc = enqSnapshot.data();

				let __ObjEnquiry = Object.assign({}, objServiceBasicVM);
				for (let [key, value] of Object.entries(activityDoc)) {
					__ObjEnquiry[key] = value;
				}

				let _log = Object.assign({}, activityLog);
				_log.service = Object.assign({}, !_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedService);

				if (_.isEmpty(_log.contact)) _log.contact = Object.assign({}, !_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer)

				setActivitylog(_log);

				setService(!_.isEmpty(__ObjEnquiry) ? __ObjEnquiry : selectedService);
				setCustomer(!_.isEmpty(__ObjEnquiry.contact) ? __ObjEnquiry.contact : selectedCustomer);
			}
		}
	};

	const getContactbyID = async id => {
		if (!_.isEmpty(id)) {
			const contSnapshot = await firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${id}`).get();
			if (contSnapshot.exists) {
				const _ObjContact = contSnapshot.data();

				let _log = Object.assign({}, activityLog);
				_log.contact = Object.assign({}, !_.isEmpty(_ObjContact) ? _ObjContact : selectedCustomer)

				setActivitylog(_log);
				setCustomer(!_.isEmpty(_ObjContact) ? _ObjContact : selectedCustomer);
			}
		}
	};

	const onCollectionHistoryUpdate = querySnapshot => {
		let _logData = [];
		querySnapshot.forEach(_log => {
			let objData = Object.assign({}, _log.data());
			objData.documentID = _log.id;
			_logData.push(objData);
		});

		if (!_.isEmpty(_logData)) {
			setNotesHistory(_.orderBy(_logData, ["modifiedDate"], ["desc"]))
		}
		else if (activityLog && activityLog.notes && activityLog.notes.trim()) {
			saveLogNote();
		}

	};

	const saveLogNote = () => {
		let noteID = firestoreDB(props.dealersettings).firestore().collection('activites').doc().id;
		firestoreDB(props.dealersettings)
			.firestore()
			.doc(`activities/${activityLog.documentID}/notesHistory/${noteID}`)
			.set({
				documentID: noteID,
				addedBy: activityLog.addedBy ? activityLog.addedBy : localStorage.uid,
				addedDate: activityLog.addedDate ? activityLog.addedDate : window.firebase.firestore.Timestamp.now(),
				addedByName: activityLog.addedByName ? activityLog.addedByName : '',
				addedFrom: 'web',
				notes: activityLog.notes,
				modifiedBy: localStorage.uid,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
			}, { merge: true })
	}

	const bindActivityNotes = () => {
		let notes = '';

		if (activityLogNotes && activityLogNotes.trim()) {
			notes = activityLogNotes.trim() + '\n';
			notes = notes + moment().format('DD/MM/YYYY hh:mm A') + ' - ' + (CommonHelper.getUserNamebyId(props.clientUsers, localStorage.uid)) + '\n\n';
		}

		_.orderBy(notesHistory, ['modifiedDate'], ['desc']).map((r) => {
			if (r.notes) {
				notes = notes + r.notes + '\n';
				if (r?.addedByName) {
					notes = notes + moment.unix(r.addedDate.seconds).format('DD/MM/YYYY hh:mm A') + ' - ' + r?.addedByName + '\n\n';
				} else if (r?.addedBy) {
					notes = notes + moment.unix(r.addedDate.seconds).format('DD/MM/YYYY hh:mm A') + ' - ' + (CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), r.addedBy)) + '\n\n';
				} else {
					notes = notes + '\n';
				}
			}
		})
		return notes;
	}


	const onCollectionUpdate = querySnapshot => {
		var slots = [];
		querySnapshot.docs.forEach(function (doc) {
			var activityData = doc.data();
			if (doc.id !== activityLog.documentID) {
				if (
					!Object.keys(slots).some(item => item === moment.unix(activityData.startDate.seconds).format('DD/MM/YYYY'),)
				) {
					slots[moment.unix(activityData.startDate.seconds).format('DD/MM/YYYY')] = [];
				}
				slots[moment.unix(activityData.startDate.seconds).format('DD/MM/YYYY')].push({
					startDate: activityData.startDate,
					endDate: activityData.endDate,
					contact: activityData.contact,
					ownerID: activityData.owner,
					owner: CommonHelper.getUserNamebyId(props.clientUsers, activityData.owner,),
					exists: activityLog.startDate.seconds >= activityData.startDate.seconds &&
						activityLog.startDate.seconds <= activityData.endDate.seconds
						? true
						: false,
				});
			}
		});
		setTestDriveSlots(slots);
		setSlotLoader(false);
	};

	const handleChangelog = (e, notes) => {
		e.preventDefault && e.preventDefault();

		if (!notes) {
			if (e.target.name === 'title' && e.target.value.length === 0) {
				inputRef.current.classList.add('input_error');
			} else {
				inputRef.current.classList.remove('input_error');
			}
		}

		setActivitylog({
			...activityLog,
			[e.target.name]:
				e.target.name === 'isDone' ? e.target.checked : e.target.value,
		});
	};

	const onMentionChange = notesText => {
		setActivityLogNotes(notesText);
		//// if (notesText)
		//// notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

		// const e = {
		// 	target: {
		// 		name: 'notes',
		// 		value: notesText,
		// 	},
		// };

		// handleChangelog(e, true);
	};

	const onMentionSelect = (option, ownerID) => {
		// console.log('select', option);
		const { name, id } = option;
		let idToPass = id
		// return if user is mentioning himself
		if (idToPass === localStorage.uid) {
			return;
		}

		if (id.includes(',')) {
			if (idToPass.includes(localStorage.uid)) {
				idToPass = idToPass.replace(`${localStorage.uid},`, '')
				idToPass = idToPass.replace(`${localStorage.uid}`, '')
			}
			if (ownerID && idToPass.includes(ownerID)) {
				idToPass = idToPass.replace(`${ownerID},`, '')
				idToPass = idToPass.replace(`${ownerID}`, '')
			}
			setTeamMentions(idToPass)
		}

		// Check to avoid adding duplicate entries in mention
		const check = mentions.filter(mention => mention.id === idToPass);

		if (check.length === 0) {
			setMentions([...mentions, { name, id: idToPass }]);
		}
	};

	const clearTeamMentions = () => {
		setTeamMentions(null)
	}

	const handleActivetab = tab => {
		if (!activityLog.inbound) {
			setActivetab(tab);
			let _data = {
				...activityLog,
				subType: tab,
				title: activityTypes.filter(e => e.type === tab)[0].name,
			}

			if (props.isNewActivity) {
				let _sdate = moment.unix(activityLog.startDate.seconds)
				_data.endDate = window.firebase.firestore.Timestamp.fromDate(['call'].includes(tab) ? _sdate.add(15, 'minutes').toDate() : _sdate.add(1, 'hours').toDate());
			}
			setActivitylog(_data);
		}
		inputRef.current.classList.remove('input_error');

	};

	const handleDateChange = (val, name) => {
		if (val) {
			val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment.unix(activityLog.startDate.seconds).format('HH:mm'))._d;

			if (!showScheduledActivities)
				setShowScheduled(true);
			setActivitylog({
				...activityLog,
				[name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
				endDate: window.firebase.firestore.Timestamp.fromDate(['call'].includes(activityLog.subType) ? moment(val).add(15, 'minutes')._d : moment(val).add(1, 'hours')._d),
			});

		}
	};
	const handleStartTimeChange = (val, name) => {
		//console.log(val)
		if (!showScheduledActivities)
			setShowScheduled(true);

		setActivitylog({
			...activityLog,
			[name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
			endDate: window.firebase.firestore.Timestamp.fromDate(['call'].includes(activityLog.subType) ? moment(val).add(15, 'minutes')._d : moment(val).add(1, 'hours')._d),
		});
	};

	const handleEndTimeChange = (val, name) => {

		if (!showScheduledActivities)
			setShowScheduled(true);

		setActivitylog({
			...activityLog,
			[name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
		});
	};

	const dateFormat = val => {
		return CommonHelper.getdateFormat(val);
	};

	const handleTemmateChange = selectedOptions => {
		let _usersList = [];

		!_.isEmpty(selectedOptions) && selectedOptions.map((data, index) => {
			if (data.type === 'team') {
				_usersList = _.union(_usersList, Object.assign([], data.user_ids));
			}
			else
				_usersList = _.union(_usersList, [data.value]);
		});

		setActivitylog({
			...activityLog,
			teammates: _usersList
		});
	};

	const handleReactSelectChange = (e, data) => {
		if (e) {

			if (!showScheduledActivities && data.name === 'owner')
				setShowScheduled(true);

			setActivitylog({
				...activityLog,
				[data.name]: e.value,
			});
			if (data.name === 'contactID') {
				setCustomer(e.doc.data());
				setContactID(e.value);
			}
			if (data.name === 'enquiryID') {
				setEnquiry(e.doc.data());
			}
		}
	};

	const saveActivity = async (activityOutcome, isDone) => {
		if (activityLog.title.length === 0) {
			inputRef.current.focus();
			inputRef.current.classList.add('input_error');
			return;
		}

		const { dealersettings } = props;
		const mandActivityNotes = dealersettings?.client?.clientSettings?.mandActivityNotes ? true : false;

		if (mandActivityNotes && activityLog.isDone === true && _.isEmpty(activityLogNotes.trim())) {
			setErrorNotes('input_error');
			return;
		}


		if (Object.keys(testdriveSlots).length > 0 &&
			(activityLog.subType === 'testdrive' ||
				(activityTypes && activityTypes.filter(e => e.type === activeTab)[0] && activityTypes.filter(e => e.type === activeTab)[0].linkVehicle))
		) {
			var slotExists = null;
			Object.keys(testdriveSlots).map(rec => {
				if (testdriveSlots[rec].some(item => item.exists === true)) {
					slotExists = testdriveSlots[rec].find(item => item.exists === true);
					return;
				}
			});
			if (slotExists) {
				Swal.fire({
					title: CommonHelper.showLocale(props, 'Test Drive Booking Exists'),
					text: `${CommonHelper.showLocale(props, 'This vehicle is already booked by')} ${slotExists.owner}  ${CommonHelper.showLocale(props, 'from')} ${moment.unix(slotExists.startDate.seconds).format('DD/MM/YYYY hh:mm A')} ${CommonHelper.showLocale(props, 'to')} ${moment.unix(slotExists.endDate.seconds).format('hh:mm A')}. ${CommonHelper.showLocale(props, 'Please select alternate slot')}`,
					icon: 'info',
				});
				return;
			}
		}

		activityLog.modifiedDate = window.firebase.firestore.Timestamp.now();
		activityLog.modifiedBy = localStorage.uid;
		activityLog.modifiedByName = props.dealersettings.name;

		if (activityOutcome) {
			activityLog.activityOutcome = activityOutcome
		}

		if (!_.isEmpty(selectedCustomer)) {
			Object.keys(selectedCustomer).forEach(key => selectedCustomer[key] === undefined ? delete selectedCustomer[key] : {});
			activityLog.contact = selectedCustomer;
		} else {
			activityLog.contact = {};
		}

		if (!_.isEmpty(selectedEnquiry)) {
			Object.keys(selectedEnquiry).forEach(key => selectedEnquiry[key] === undefined ? delete selectedEnquiry[key] : {});
			activityLog.enquiry = selectedEnquiry
		} else {
			activityLog.enquiry = {};
		}

		if (!_.isEmpty(selectedTIP)) {
			Object.keys(selectedTIP).forEach(key => selectedTIP[key] === undefined ? delete selectedTIP[key] : {});
			activityLog.tradeinPro = selectedTIP
		} else {
			activityLog.tradeinPro = {};
		}

		if (!_.isEmpty(selectedService)) {
			Object.keys(selectedService).forEach(key => selectedService[key] === undefined ? delete selectedService[key] : {});
			activityLog.service = selectedService
		} else {
			activityLog.service = {};
		}

		if (!_.isEmpty(selectedStock)) {
			Object.keys(selectedStock).forEach(key => selectedStock[key] === undefined ? delete selectedStock[key] : {});
			activityLog.stock = selectedStock;
		} else {
			activityLog.stock = {};
		}

		if (activityLog.subType !== 'testdrive' &&
			(activityTypes && activityTypes.filter(e => e.type === activeTab)[0] && !activityTypes.filter(e => e.type === activeTab)[0].linkVehicle)) {
			activityLog.stockID = null;
		}

		if (mentions.length > 0) {
			let convertedIDs = []
			let rawIDs = mentions.map(mentionObject => mentionObject.id)
			rawIDs.forEach(id => {
				if (id.includes(',')) {
					let allIDs = id.split(',')
					allIDs.forEach(a => {
						convertedIDs.push(a)
					})
				} else {
					convertedIDs.push(id)
				}
			})

			if (activityLog.mentions && activityLog.mentions.length > 0) {
				activityLog.mentions = _.uniq([...activityLog.mentions, ...convertedIDs])
			} else {
				activityLog.mentions = _.uniq(convertedIDs)
			}
		}

		if (activityOldLog && activityOldLog.isDone === false && activityLog.isDone === true) {
			activityLog.completedBy = localStorage.uid;
			activityLog.completedDate = window.firebase.firestore.Timestamp.now();
		}

		if (activityOldLog && activityOldLog.isDone === true && activityLog.isDone === false) {
			activityLog.activityOutcome = null;
		}

		activityLog.notes = bindActivityNotes();

		delete activityLog.start;
		delete activityLog.end;
		delete activityLog.searchUsers;
		if (activityLog.isFromComplete) delete activityLog.isFromComplete;
		if (isDone === true) {
			setDoneLoader(true)
			activityLog.isDone = true
		} else {
			setLoader(true);
		}

		activityLog.contactID = activityLog.contactID ? activityLog.contactID : null;
		activityLog.enquiryID = activityLog.enquiryID ? activityLog.enquiryID : null;
		activityLog.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

		// let _usersList = [];
		// if (activityLog.owner)
		// 	_usersList.push(activityLog.owner);

		// if (!_.isEmpty(activityLog.teammates))
		// 	_usersList = _.union(_usersList, activityLog.teammates);

		// if (!_.isEmpty(activityLog.teams) && !_.isEmpty(allTeams)) {
		// 	activityLog.teams.forEach(teamID => {
		// 		_usersList = _.union(_usersList, (allTeams.filter(m => m.value === teamID)[0] ? allTeams.filter(m => m.value === teamID)[0].user_ids : []));
		// 	});
		// }

		// // if (!_.isEmpty(activityLog.mentions))
		// // 	_usersList = _.union(_usersList, activityLog.mentions);

		// if (!_.isEmpty(_usersList))
		// 	activityLog.searchUsers = _usersList;
		console.log(activityLog)
		firestoreDB(props.dealersettings).firestore().doc(`activities/${activityLog.documentID}`)
			.set(activityLog, { merge: true })
			.then(snapshot => {
				setLoader(false);
				setDoneLoader(false);
				toast.notify('Log updated successfully', {
					duration: 2000,
				});

				if (activityLogNotes && activityLogNotes.trim()) {
					let noteID = firestoreDB(props.dealersettings).firestore().collection('activites').doc().id;
					firestoreDB(props.dealersettings)
						.firestore()
						.doc(`activities/${activityLog.documentID}/notesHistory/${noteID}`)
						.set({
							documentID: noteID,
							addedBy: localStorage.uid,
							addedDate: window.firebase.firestore.Timestamp.now(),
							addedByName: props.dealersettings.name,
							addedFrom: 'web',
							notes: activityLogNotes.trim(),
							modifiedBy: localStorage.uid,
							modifiedDate: window.firebase.firestore.Timestamp.now(),
							projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
						}, { merge: true })
				}

				if ((props.isNewActivity || (activityOldLog && (JSON.stringify(activityOldLog.startDate) !== JSON.stringify(activityLog.startDate) || activityOldLog.isDone !== activityLog.isDone)))
					&& !_.isEmpty(activityLog.enquiryID)) {
					//update enquiry modified date if activity is mark as done 
					let updateEnq = {
						modifiedBy: localStorage.uid,
						modifiedDate: window.firebase.firestore.Timestamp.now()
					}
					if (activityLog.isDone === true) {
						updateEnq.unactionedNotify = enqUnActionStatus.COMPLETED;
					}
					firestoreDB(props.dealersettings).firestore().doc(`enquiries/${activityLog.enquiryID}`).set({ ...updateEnq }, { merge: true })
				}

				if (activityLog.stockID && !activityLog.isDone) {
					CommonHelper.saveLoanAppointment(activityLog, 'activities', props.dealersettings);
				}

				if (props.isNewActivity && isGoogleCalendarEnabled) {
					console.log('createEvent Start', activityLog)

					const attendees = []
					if (
						!_.isEmpty(activityLog.contact) &&
						!_.isEmpty(activityLog.contact.email) &&
						!_.isEmpty(activityLog.contact.firstName) &&
						!_.isEmpty(activityLog.contact.lastName)
					) {
						attendees.push({ "email": activityLog.contact.email })
					}
					if (!_.isEmpty(activityLog.teammates)) {
						props.clientUsers.forEach(user => {
							if (
								activityLog.teammates.includes(user.documentID) &&
								!_.isEmpty(user.email) &&
								!_.isEmpty(user.name)
							) {
								attendees.push({ "email": user.email })
							}
						})
					}

					//console.log('attendees', attendees)

					const reqObj = {
						subType: activityLog.subType,
						refreshToken: dealersettings.googleCalendar.refreshToken,
						location: dealersettings?.client?.address || '',
						// timezone: dealersettings.client.timezone ? dealersettings.client.timezone : moment.tz.guess(),
						timezone: moment.tz.guess(),
						startTime: moment(activityLog.startDate.toDate()).toISOString(),
						endTime: moment(activityLog.endDate.toDate()).toISOString(),
						subject: activityLog.title ? activityLog.title : " ",
						reminder: activityLog.reminder || 0,
						body: {
							contentType: "HTML",
							content: activityLogNotes ? activityLogNotes : " "
						},
					}

					if (!_.isEmpty(attendees)) {
						reqObj.attendees = attendees
					}
					axios.post(`${apiURL}gcalendar-api/api/gcalendar/createEvent`, reqObj,)
						.then(createEventResp => {
							console.log(`createEventResp activityLog.documentID`, activityLog.documentID, createEventResp)
							if (createEventResp?.data?.googleEventID) {
								firestoreDB(props.dealersettings).firestore().doc(`activities/${activityLog.documentID}`).update({ googleEventID: createEventResp.data.googleEventID }).catch(err => console.log(err))
							}
						})
						.catch(err => console.log(err))
				} else if (!props.isNewActivity &&
					!_.isEmpty(activityLog.googleEventID) && isGoogleCalendarEnabled) {
					console.log('updateEvent Start googleEventID')

					const attendees = []
					if (
						!_.isEmpty(activityLog.contact) &&
						!_.isEmpty(activityLog.contact.email) &&
						!_.isEmpty(activityLog.contact.firstName) &&
						!_.isEmpty(activityLog.contact.lastName)
					) {
						attendees.push({
							"email": activityLog.contact.email
						})
					}
					if (!_.isEmpty(activityLog.teammates)) {
						props.clientUsers.forEach(user => {
							if (
								activityLog.teammates.includes(user.documentID) &&
								!_.isEmpty(user.email) &&
								!_.isEmpty(user.name)
							) {
								attendees.push({
									"email": user.email
								})
							}
						})
					}

					const reqObj = {
						subType: activityLog.subType,
						refreshToken: dealersettings.googleCalendar.refreshToken,
						googleEventID: activityLog.googleEventID,
						location: dealersettings?.client?.address || '',
						// timezone: dealersettings.client.timezone ? dealersettings.client.timezone : moment.tz.guess(),
						timezone: moment.tz.guess(),
						startTime: moment(activityLog.startDate.toDate()).toISOString(),
						endTime: moment(activityLog.endDate.toDate()).toISOString(),
						subject: activityLog.title ? activityLog.title : " ",
						reminder: activityLog.reminder || 0,
						body: {
							contentType: "HTML",
							content: activityLogNotes ? activityLogNotes : " "
						},
					}

					if (!_.isEmpty(attendees)) {
						reqObj.attendees = attendees
					}

					//console.log('attendees', attendees)

					axios.post(`${apiURL}gcalendar-api/api/gcalendar/updateEvent`, reqObj)
						.then(updateEventResp => {
							console.log(`updateEventResp activityLog.documentID`, activityLog.documentID, updateEventResp)
						})
						.catch(err => console.log(err))
				}
				if (props.isNewActivity && isOutlookEnabled) {
					console.log('createEvent Start', activityLog)

					const attendees = []
					if (
						!_.isEmpty(activityLog.contact) &&
						!_.isEmpty(activityLog.contact.email) &&
						!_.isEmpty(activityLog.contact.firstName) &&
						!_.isEmpty(activityLog.contact.lastName)
					) {
						attendees.push({
							"emailAddress": {
								"address": activityLog.contact.email,
								"name": CommonHelper.displayContactName([], activityLog.contact)
							},
							"type": "required"
						})
					}
					if (!_.isEmpty(activityLog.teammates)) {
						props.clientUsers.forEach(user => {
							if (
								activityLog.teammates.includes(user.documentID) &&
								!_.isEmpty(user.email) &&
								!_.isEmpty(user.name)
							) {
								attendees.push({
									"emailAddress": {
										"address": user.email,
										"name": user.name
									},
									"type": "required"
								})
							}
						})
					}

					console.log('attendees', attendees)

					const reqObj = {
						refreshToken: dealersettings.outlookCalendar.refreshToken,
						// timezone: dealersettings.client.timezone ? dealersettings.client.timezone : moment.tz.guess(),
						timezone: moment.tz.guess(),
						location: dealersettings?.client?.address || '',
						startTime: moment(activityLog.startDate.toDate()).toISOString(),
						endTime: moment(activityLog.endDate.toDate()).toISOString(),
						subject: activityLog.title ? activityLog.title : " ",
						body: {
							contentType: "HTML",
							content: activityLogNotes ? activityLogNotes : " "
						},
					}

					if (!_.isEmpty(attendees)) {
						reqObj.attendees = attendees
					}

					axios.post(`${apiURL}outlook-api/api/outlook/createEvent`, reqObj,)
						.then(createEventResp => {
							console.log(`createEventResp activityLog.documentID`, activityLog.documentID, createEventResp)
							if (createEventResp?.data?.outlookEventID) {
								firestoreDB(props.dealersettings)
									.firestore()
									.doc(`activities/${activityLog.documentID}`)
									.update({ outlookEventID: createEventResp.data.outlookEventID })
									.catch(err => console.log(err))
							}
						})
						.catch(err => console.log(err))
				} else if (!props.isNewActivity &&
					!_.isEmpty(activityLog.outlookEventID) && isOutlookEnabled) {
					console.log('updateEvent Start',)


					const attendees = []
					if (
						!_.isEmpty(activityLog.contact) &&
						!_.isEmpty(activityLog.contact.email) &&
						!_.isEmpty(activityLog.contact.firstName) &&
						!_.isEmpty(activityLog.contact.lastName)
					) {
						attendees.push({
							"emailAddress": {
								"address": activityLog.contact.email,
								"name": CommonHelper.displayContactName([], activityLog.contact)
							},
							"type": "required"
						})
					}
					if (!_.isEmpty(activityLog.teammates)) {
						props.clientUsers.forEach(user => {
							if (
								activityLog.teammates.includes(user.documentID) &&
								!_.isEmpty(user.email) &&
								!_.isEmpty(user.name)
							) {
								attendees.push({
									"emailAddress": {
										"address": user.email,
										"name": user.name
									},
									"type": "required"
								})
							}
						})
					}

					const reqObj = {
						refreshToken: dealersettings.outlookCalendar.refreshToken,
						outlookEventID: activityLog.outlookEventID,
						location: dealersettings?.client?.address || '',
						// timezone: dealersettings.client.timezone ? dealersettings.client.timezone : moment.tz.guess(),
						timezone: moment.tz.guess(),
						startTime: moment(activityLog.startDate.toDate()).toISOString(),
						endTime: moment(activityLog.endDate.toDate()).toISOString(),
						subject: activityLog.title ? activityLog.title : " ",
						body: {
							contentType: "HTML",
							content: activityLogNotes ? activityLogNotes : " "
						},
					}

					if (!_.isEmpty(attendees)) {
						reqObj.attendees = attendees
					}

					console.log('attendees', attendees)

					axios.post(`${apiURL}outlook-api/api/outlook/updateEvent`, reqObj)
						.then(updateEventResp => {
							console.log(`updateEventResp activityLog.documentID`, activityLog.documentID, updateEventResp)
						})
						.catch(err => console.log(err))
				}

				// if (activityLog.appointmentID && activityLog.stockID && activityLog.isDone) {
				// 	CommonHelper.deleteLoanAppointments(activityLog.documentID, 'activities',props.dealersettings);
				// }

				if (activityOldLog && activityOldLog.isDone === true && activityLog.isDone === true) {
					// checking if the activity was already marked as done
					props.handleClose(activityLog, true);
				} else {
					props.handleClose(activityLog);
				}
			})
			.catch(error => {
				setLoader(false);
				setDoneLoader(false);
				console.error(error);
				toast.notify('Something went wrong', {
					duration: 2000,
				});
			});
	};

	const deleteActivity = () => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				setDeleteloader(true);
				activityLog.modifiedDate = window.firebase.firestore.Timestamp.now();
				activityLog.modifiedBy = localStorage.uid;
				activityLog.isDeleted = true;
				delete activityLog.start;
				delete activityLog.end;
				delete activityLog.searchUsers;
				firestoreDB(props.dealersettings)
					.firestore()
					.doc(`activities/${activityLog.documentID}`)
					.set({
						modifiedDate: window.firebase.firestore.Timestamp.now(),
						modifiedBy: localStorage.uid,
						modifiedFrom: 'web',
						isDeleted: true
					}, { merge: true })
					.then(snapshot => {
						setDeleteloader(false);
						props.handleDelete(activityLog);
						toast.notify('Log deleted successfully', {
							duration: 2000,
						});

						if (activityLog.appointmentID) {
							CommonHelper.deleteLoanAppointments(activityLog.documentID, 'activities', props.dealersettings);
						}

						let logNotes = 'Log deleted';
						if (!_.isEmpty(activityLog) && activityLog.type)
							logNotes = activityLog.type + ': ' + (activityLog.subType ? activityLog.subType : '') + ' deleted';

						let _objLogData = {
							notes: logNotes,
							type: 'activities',
							subType: 'delete',
							recordId: activityLog.documentID,
						}
						_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
						CommonHelper.saveAuditLog(_objLogData);
						//console.log('deleteEvent Start', activityLog)

						if (activityLog.outlookEventID && isOutlookEnabled) {
							//console.log('deleteEvent Start 2',)
							axios.post(
								`${apiURL}outlook-api/api/outlook/deleteEvent`,
								{
									refreshToken: props.dealersettings.outlookCalendar.refreshToken,
									outlookEventID: activityLog.outlookEventID,
								},
							)
								.then(deleteEventResp => {
									console.log(`deleteEventResp activityLog.documentID`, activityLog.documentID, deleteEventResp)
								})
								.catch(err => console.log(err))
						}
						if (activityLog.googleEventID && isGoogleCalendarEnabled) {
							//console.log('deleteEvent Start 2',)
							axios.post(
								`${apiURL}gcalendar-api/api/gcalendar/deleteEvent`,
								{
									subType: activityLog.subType,
									refreshToken: props.dealersettings.googleCalendar.refreshToken,
									googleEventID: activityLog.googleEventID,
								},
							)
								.then(deleteEventResp => {
									console.log(`deleteEventResp activityLog.documentID`, activityLog.documentID, deleteEventResp)
								})
								.catch(err => console.log(err))
						}
					})
					.catch(error => {
						setDeleteloader(false);
						console.error(error);
						toast.notify('Something went wrong', {
							duration: 2000,
						});
					});
			}
		});
	};

	const handleContactClose = (id, objData) => {
		if (objData) {
			setActivitylog({
				...activityLog,
				['contactID']: id,
				['clientID']: localStorage.defaultModule === 'tradeinPro' ? objData.clientID : activityLog.clientID
			});
			setCustomer(objData);
			setContactID(id);
		}
		setContactPopover({ show: false, target: null });
	};

	const handleEnquiryClose = (id, objData) => {
		setActivitylog({
			...activityLog,
			['enquiryID']: id,
		});
		setEnquiry(objData);
		setEnquiryPopover({ show: false, target: null });
	};

	const handleTIPClose = (id, objData) => {
		if (objData) {
			setActivitylog({
				...activityLog,
				['tradeinProID']: id,
				['clientID']: objData.clientID
			});
		}
		setTIP(objData);
		setTIPPopover({ show: false, target: null });
	};

	const handleServiceClose = (id, objData) => {
		setActivitylog({
			...activityLog,
			['serviceID']: id,
		});
		setService(objData);
		setServicePopover({ show: false, target: null });
	};

	const handleStockClose = (id, objData) => {
		setActivitylog({
			...activityLog,
			['stockID']: id,
		});
		setStock(objData);
		setStockID(id);
		setStockPopover({ show: false, target: null });
		setStockModel({ show: false, ID: '', title: '', clsActive: '' });
	};

	const handleAddStock = () => {
		setStockPopover({ show: false, target: null });
		setStockModel({
			show: true,
			ID: '',
			title: 'Add Stock',
			clsActive: 'overlay-modal active',
		});
	};

	const handleAddLogClose = (id) => {

		if (id && document.getElementById('linked-item-' + id)) {
			setTimeout(function () {
				document
					.getElementById('linked-item-' + id)
					.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
			}, 1000);
		}
		setLinkLogModel({ show: false, ID: '', title: '', clsActive: '' });
	};

	const handleAddLog = () => {
		setLinkLogModel({
			show: true,
			ID: '',
			title: 'Add Log',
			clsActive: 'overlay-modal active',
		});
	};



	const handleModelClose = () => {
		setEmaillog(null)
		setPreviewModel(false)
	}

	const handleStatusUpdateDone = (objData) => {
		if (!_.isEmpty(objData) && objData.status) {
			setEnquiry({
				...selectedEnquiry,
				['statusValue']: objData.status,
				['status']: objData.status,
			});
		}
	}

	const isEditable = _.isEmpty(activityLog) ? true : ((localStorage.defaultModule !== 'oem' && localStorage.defaultModule !== 'tradeinPro' && activityLog.clientID !== props.dealersettings.client.id) || props.isReadOnlyView === true) ? false : ((
		((!_.isEmpty(activityLog.searchUsers) && activityLog.searchUsers.indexOf(props.dealersettings.id) >= 0)
			|| activityLog.addedBy === props.dealersettings.id)
		||
		(!_.isEmpty(props.dealersettings.rolePermissions) &&
			!_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
			props.dealersettings.rolePermissions.permissions.modifyOtherActivity)
	) ? true : false);

	const modifyActivityOwner =
		(!_.isEmpty(props.dealersettings.rolePermissions) &&
			!_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
			props.dealersettings.rolePermissions.permissions.modifyActivityOwner) ? true : false;


	const checkOutcomeAndSaveActivity = (isDone) => {
		const { dealersettings } = props
		if (
			dealersettings &&
			dealersettings.client &&
			dealersettings.client.settings &&
			!_.isEmpty(dealersettings.client.settings.activityOutcome) &&
			dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
			activityOldLog && activityOldLog.isDone === false && activityLog.isDone === true &&
			activityOldLog.subType && activityOldLog.subType === 'call'
		) {
			const isMandatory = ((!_.isEmpty(dealersettings) &&
				!_.isEmpty(dealersettings.client) &&
				!_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
			const activtyOutcomes = [];
			_.orderBy(
				dealersettings.client.settings.activityOutcome.filter(
					item => item.active === true,
				),
				['name'],
				['asc'],
			).forEach((data, index) => {
				activtyOutcomes.push({
					value: data.value,
					name: data.name,
					label: data.name,
					price: data.price,
				});
			});
			// console.log('activtyOutcomes', activtyOutcomes)
			// console.log('isMandatory', isMandatory)
			setShowOutcomeModal({
				show: true,
				clsActive: 'overlay-modal active',
				isMandatory,
				activtyOutcomes,
				isDone
			});
		} else {
			saveActivity(null, isDone);
		}
	}

	const handleCloseOutcomeModal = (isMandatory, selectedStatus, data, isDone) => {
		console.log('handleCloseOutcomeModal', isMandatory, selectedStatus)

		if (isMandatory) {
			if (selectedStatus) {
				saveActivity(selectedStatus, isDone)
			} else {
				setShowOutcomeModal({
					show: false,
					clsActive: '',
					isMandatory: false,
					activtyOutcomes: null
				});
				return
			}
		} else {
			if (selectedStatus) {
				saveActivity(selectedStatus, isDone)
			} else {
				saveActivity(null, isDone)
			}
		}
		setShowOutcomeModal({
			show: false,
			clsActive: '',
			isMandatory: false,
			activtyOutcomes: null
		});
	}

	const onHide = () => {
		if (!mandScheduleNextActivity) props.handleClose();
	}

	const removeLink = (_type) => {
		let _activityLog = { ...activityLog }

		if (['contact'].includes(_type)) {
			setCustomer(null);
			_activityLog.contactID = null;
			_activityLog.enquiryID = null;
			_activityLog.tradeinProID = null;
			_activityLog.serviceID = null;
		}
		if (['contact', 'enquiry'].includes(_type)) { setEnquiry(null); _activityLog.enquiryID = null; }
		if (['contact', 'tradeinPro'].includes(_type)) { setTIP(null); _activityLog.tradeinProID = null; }
		if (['contact', 'service'].includes(_type)) { setService(null); _activityLog.serviceID = null; }

		setActivitylog(_activityLog);
	}

	const removeStockLink = () => {
		setStock(null);
		setStockID(null);
		setTestDriveSlots([]);
		setActivitylog({
			...activityLog,
			stockID: '',
		});
	}

	//console.log('selectedEnquiry', selectedEnquiry)
	return _.isEmpty(activityLog) ? (
		<></>
	) : (
		<>
			<Modal
				show={props.show}
				onHide={onHide}
				backdrop='static'
				backdropClassName={props.show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog-scrollable modal-dialog-centered modal-add-company'
				enforceFocus={false}
			>
				<Modal.Header closeButton={mandScheduleNextActivity ? false : true}>
					<Modal.Title className="w-100"><Translate text={isEditable ? props.title : 'activity'} />
						{/* {(() => {
							return <> */}
						{
							activityOldLog?.inbound
								?
								<div className="float-right tracked-appointment-statuswrap">
									<span className="badge badge-pill badge-tracked-appointment">
										<span style={{ fontWeight: 'bold' }}> <Translate text={'Tracked Appointment'} /></span>
									</span>
								</div>
								:
								<></>
						}
						{/* </>
						})()} */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modal-body'>
					<div className='container-fluid'>
						<div className={`row ${isEditable ? '' : 'form-disable'}`}>
							<div className=' pop-layout form-style'>

								<Tab.Container defaultActiveKey={activeTab} activeKey={activeTab}>
									<Nav
										as='div'
										bsPrefix='ul-nav'
										className={`activity-buttongroup mb-3 ${activityLog?.inbound ? 'btn-disable' : ''}`}
										onSelect={k => handleActivetab(k)}
									>
										{activityTypes.map((activity, index) => {
											return (
												<Nav.Item
													as='div'
													bsPrefix='add-activity-nav'
													key={index}
												>
													<Nav.Link
														eventKey={activity.type}
														bsPrefix='li-a-nav'
														title={activity.name}
													>
														{<i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, activity.ico, true, activity.icoVehicle)}></i>}
													</Nav.Link>
												</Nav.Item>
											);
										})}
									</Nav>
								</Tab.Container>

								<div className='form-group'>
									<input
										type='text'
										className='form-control'
										id=''
										name='title'
										value={activityLog.title}
										onChange={e => handleChangelog(e)}
										autoComplete='off'
										ref={inputRef}
									/>
								</div>

								{(() => {
									return <>
										<div className='form-row'>
											<div className='form-group col-md-3'>
												<label ><Translate text={'date'} /></label>
												<div className='inner-addon right-addon'>
													{
														props.isFromLayout
															?
															<>
																<ReactDatePicker
																	value={moment.unix(activityLog.startDate.seconds).format('YYYY-MM-DD')}
																	name={'startDate'}
																	onChange={handleDateChange}
																	format={dateFormat}
																	placeholder='DD/MM/YYYY'
																	className={`form-control`}
																	readOnly={true}
																	isClearable={false}
																/>
															</>
															:
															<>
																<AntDatePicker
																	value={moment
																		.unix(activityLog.startDate.seconds)
																		.format('YYYY-MM-DD')}
																	name={'startDate'}
																	onChange={e => {
																		handleDateChange(e, 'startDate');
																	}}
																	format='DD/MM/YYYY'
																	placeholder='DD/MM/YYYY'
																	className={`form-control`}
																/>
															</>
													}


												</div>
											</div>
											<div className='form-group col-md-3'>
												<label><Translate text={'from'} /></label>
												<div className='inner-addon right-addon'>
													<i className='bg-icons ico icon-time'></i>

													<ReactTimePicker
														value={moment.unix(activityLog.startDate.seconds)._d}
														name={'startDate'}
														onChange={handleStartTimeChange}
														timeIntervals={15}
														placeholder='h:mm aa'
														className={`form-control`}
														minTime={workingHours ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[0]}`)._d : null}
														maxTime={workingHours ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`)._d : null}
													/>
												</div>
											</div>
											{
												activityLog.subType === 'testdrive' ? (
													<>
														<div className='form-group col-md-3'>
															<label ><Translate text={'to'} /></label>
															<div className='inner-addon right-addon'>
																{
																	props.isFromLayout
																		?
																		<>
																			<ReactDatePicker
																				value={moment.unix(activityLog.endDate.seconds).format('YYYY-MM-DD')}
																				name={'endDate'}
																				onChange={handleDateChange}
																				format={dateFormat}
																				placeholder='DD/MM/YYYY'
																				className={`form-control`}
																				readOnly={true}
																				isClearable={false}
																			/>
																		</>
																		:
																		<>
																			<AntDatePicker
																				value={moment
																					.unix(activityLog.endDate.seconds)
																					.format('YYYY-MM-DD')}
																				name={'endDate'}
																				onChange={e => {
																					handleDateChange(e, 'endDate');
																				}}
																				format='DD/MM/YYYY'
																				placeholder='DD/MM/YYYY'
																				className={`form-control`}
																			/>
																		</>
																}


															</div>
														</div>
														<div className='form-group col-md-3'>
															<label className='w-100'>&nbsp;</label>
															<div className='inner-addon right-addon'>
																<i className='bg-icons ico icon-time'></i>

																<ReactTimePicker
																	value={moment.unix(activityLog.endDate.seconds)._d}
																	name={'endDate'}
																	onChange={handleEndTimeChange}
																	timeIntervals={15}
																	placeholder='h:mm aa'
																	className={`form-control`}
																	minTime={moment.unix(activityLog.startDate.seconds).format('YYYY-MM-DD') === moment.unix(activityLog.endDate.seconds).format('YYYY-MM-DD') ? moment.unix(activityLog.startDate.seconds)._d : (workingHours ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[0]}`)._d : null)}
																	maxTime={moment.unix(activityLog.startDate.seconds).format('YYYY-MM-DD') === moment.unix(activityLog.endDate.seconds).format('YYYY-MM-DD') ? moment.unix(CommonHelper.getEndDate(activityLog.startDate).seconds)._d : (workingHours ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`)._d : null)}
																/>
															</div>
														</div>
													</>
												) : (
													<div className='form-group col-md-3'>
														<label ><Translate text={'to'} /></label>
														<div className='inner-addon right-addon'>
															<i className='bg-icons ico icon-time'></i>

															<ReactTimePicker
																value={moment.unix(activityLog.endDate.seconds)._d}
																name={'endDate'}
																onChange={handleEndTimeChange}
																timeIntervals={15}
																placeholder='h:mm aa'
																className={`form-control`}
																minTime={moment.unix(activityLog.startDate.seconds)._d}
																maxTime={workingHours ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`)._d : moment.unix(CommonHelper.getEndDate(activityLog.startDate).seconds,)._d}
															/>
														</div>
													</div>
												)
											}
											<div className={`form-group col-md-${activityLog.subType === 'testdrive' ? '12' : '3'}`}>
												<label ><Translate text={'reminder'} /></label>
												<ReactSelect
													options={reminderOptions}
													name={'reminder'}
													onChange={handleReactSelectChange}
													value={activityLog.reminder}
													classNamePrefix={`basic-select`}
													removeClearable={true}
													isSearchable={true}
												></ReactSelect>
											</div>
										</div>
									</>
								})()}
								{(() => {
									return <>
										{
											!_.isEmpty(scheduledActivities) && (<div className="calendarlist-activity-item">
												<div className="scheduled-activities-title"> <Translate text={'Scheduled Activities'} /></div>
												{
													scheduledActivities.map((log, index) => {
														return (
															<div key={index} className="calendarlist-activity-loop">
																<div className="calendarlist-activity-list">
																	<div className="calendarlist-activity-title">
																		<div className="calendarlist-activity-icon">
																			<i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, log.subType)}`}></i>
																		</div>
																		<a href="#" onClick={(e) => { e.preventDefault(); /*handleEditActivity(log.documentID) */ }}>{log.title}</a>
																	</div>
																	<div className="calendarlist-activity-date">{moment.unix(log.startDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner)}</div>
																</div>
															</div>
														)
													})
												}
											</div>)
										}
									</>
								})()}


								<div className='form-group'>
									<label ><Translate text={'linkContact'} /></label>
									{!_.isEmpty(selectedCustomer) ? <>
										<ContactLink
											{...props}
											selectedCustomer={selectedCustomer}
											activityOldLog={activityOldLog}
											activityLog={activityLog}
											removeLink={removeLink}
										/>
									</> : <>
										<input
											type='text'
											className='form-control'
											placeholder='Select Contact'
											readOnly
											onClick={e => {
												e.preventDefault();
												setContactPopover({ show: true, target: e.target });
											}}
										/>
									</>}
								</div>

								{(() => {
									return <>
										{
											(!['tradeinPro', 'service'].includes(localStorage.defaultModule) || !_.isEmpty(selectedEnquiry?.documentID)) && (
												<div className='form-group'>
													<label ><Translate text={'Link Enquiry'} /></label>
													{!_.isEmpty(selectedEnquiry) ?
														<>
															<EnquiryLink
																{...props}
																selectedEnquiry={selectedEnquiry}
																activityLog={activityLog}
																removeLink={removeLink}
																handleStatusUpdateDone={handleStatusUpdateDone}
															/>
														</>
														: <><input
															type='text'
															className='form-control'
															placeholder='Select Enquiry'
															readOnly
															onClick={e => {
																e.preventDefault();
																setEnquiryPopover({ show: true, target: e.target });
															}}
														/></>
													}
												</div>)

										}
									</>
								})()}

								{(() => {
									return <>
										{
											(['service'].includes(localStorage.defaultModule) || !_.isEmpty(selectedService?.documentID)) && (
												<div className='form-group'>
													<label ><Translate text={'Link Service'} /></label>
													{!_.isEmpty(selectedService) ? <>
														<ServiceLink
															{...props}
															selectedService={selectedService}
															activityLog={activityLog}
															removeLink={removeLink}
														/>
													</> : <>

														<input
															type='text'
															className='form-control'
															placeholder='Select Service'
															readOnly
															onClick={e => {
																e.preventDefault();
																setServicePopover({ show: true, target: e.target });
															}}
														/>
													</>}
												</div>
											)}
									</>
								})()}

								{(() => {
									return <>
										{
											(['tradeinPro'].includes(localStorage.defaultModule) || !_.isEmpty(selectedTIP?.documentID)) && (
												<div className='form-group'>
													<label ><Translate text={'Link Trade-In'} /></label>
													{selectedTIP ? <>
														<TradeinProLink
															{...props}
															selectedTIP={selectedTIP}
															activityLog={activityLog}
															removeLink={removeLink}
														/>
													</> : <>
														<input
															type='text'
															className='form-control'
															placeholder='Select Tradein'
															readOnly
															onClick={e => {
																e.preventDefault();
																setTIPPopover({ show: true, target: e.target });
															}}
														/>
													</>
													}
												</div>)
										}
									</>
								})()}

								{
									(activityLog.subType === 'testdrive' ||
										(activityTypes && activityTypes.filter(e => e.type === activeTab)[0] && activityTypes.filter(e => e.type === activeTab)[0].linkVehicle))
										? (
											<div className='form-group'>
												<label ><Translate text={'linkVehicle'} /></label>
												{selectedStock ? <>
													<StockLink
														{...props}
														selectedStock={selectedStock}
														activityLog={activityLog}
														removeLink={removeStockLink}
														testdriveSlots={testdriveSlots}
														slotLoader={slotLoader}
													/>
												</> : <>
													<input
														type='text'
														className='form-control'
														placeholder='Select Vehicle'
														readOnly
														onClick={e => {
															e.preventDefault();
															setStockPopover({ show: true, target: e.target });
														}}
													/>
												</>}
											</div>
										) : (
											<></>
										)
								}


								<div className='form-group'>
									<label ><Translate text={'teammate'} /></label>
									<ReactMultiSelect
										options={allTeamUsers}
										name={'teammates'}
										placeholder={'select teammate'}
										onChange={handleTemmateChange}
										value={activityLog.teammates}
										classNamePrefix={`basic-select`}
										isMulti={true}
										isSearchable={true}
										menuPlacement='top'
										isGroup={true}
									></ReactMultiSelect>
								</div>
								<div className='form-group'>
									<label ><Translate text={'owner'} /></label>
									<ReactSelect
										options={_.orderBy(allUsers, ["searchlabel"], ["asc"])}
										name={'owner'}
										onChange={handleReactSelectChange}
										value={activityLog.owner}
										classNamePrefix={`basic-select`}
										removeClearable={true}
										isDisabled={(activityLog.owner && modifyActivityOwner) ? false : true}
										isSearchable={true}
									></ReactSelect>
								</div>
								<div className='form-group mt-3'>

									<div className="w-100 d-inline-block mb-2">
										<label ><Translate text={'notes'} /></label>
										{
											!_.isEmpty(selectedEnquiry) || !_.isEmpty(selectedService) || !_.isEmpty(selectedTIP)
												?
												<>
													<div className="float-right linked-activities-add">
														<button type="button"
															className="btn btn-default float-right"
															onClick={(e) => { setShowLogModel(true); }}
														>
															<i className="ico icon-note"></i>View Logs
														</button>
													</div>
												</>
												:
												<></>
										}


									</div>



									<MentionEditor
										mentionsData={mentionsData}
										onMentionChange={onMentionChange}
										onMentionSelect={onMentionSelect}
										notes={activityLogNotes ? activityLogNotes : ''}
										focusOnMount={false}
										isNoteEmpty={errorNotes === 'input_error' && _.isEmpty(activityLogNotes.trim()) ? true : false}
										teamMentions={teamMentions}
										clearTeamMentions={clearTeamMentions}
										clientUsers={props.clientUsers}
										ownerID={activityLog.owner ? activityLog.owner : ''}
									/>


								</div>
								{
									!_.isEmpty(notesHistory) && (<div className="linked-activities-wrap mt-1">
										<div className="linked-activities-title p-0"> <Translate text={'Notes History'} /></div>
										<div className="enquiry-flow mt-0 mb-3">
											<div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
												<div className="flow-timelinebar"></div>
												{
													notesHistory.map((obj, index) => {
														return (
															<div key={index} className="enquiry-flow-item note">
																<div className="flow-item  note  ">
																	<div className="flow-item-content">
																		<div className="flow-text-wrap">
																			<div className="flow-text text-area-space note">
																				<div dangerouslySetInnerHTML={{
																					__html: CommonHelper.stringtoURL(obj.notes),
																				}}
																				></div>
																				<div className="flow-notes-sub mt-1">
																					<span>{obj.addedDate ? moment.unix(obj.addedDate.seconds).format('DD/MM/YYYY h:mm A') : ''}</span>
																					{
																						obj.addedBy
																							?
																							<>{' - '}{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), obj.addedBy)}</>
																							:
																							<></>
																					}
																				</div>

																			</div>
																		</div>
																	</div>
																</div>
																<span className="flow-icon"> <i className="ico icon-note"></i> </span>
															</div>
														);
													})
												}
											</div>
										</div>
									</div>)}


								{
									(!props.isNewActivity && activityLog && activityLog.contactID) && (
										<div className="linked-activities-wrap">
											<div className="linked-activities-title"> <Translate text={'Linked Activities'} />
												<div className=" float-right linked-activities-add">
													<button type="button"
														className="btn btn-primary float-right"
														onClick={(e) => { handleAddLog(); }}
													>
														<i className="ico icon-add"></i> <Translate text={'Add Log'} />
													</button>
												</div>
											</div>
											<div className="enquiry-flow mt-0 mb-3">
												{
													!_.isEmpty(linkedActivities)
														?
														<>
															<div className="enquiry-flow-wrap mt-0 mb-4 border-bottom pb-3">
																<div className="flow-timelinebar"></div>
																{
																	linkedActivities.map((log, index) => {
																		return <div id={`linked-item-${log.documentID}`} key={index}><ActivityTag
																			key={index}
																			title={log.title}
																			activityDate={log.startDate}
																			id={log.documentID}
																			type={log.subType}
																			addedBy={log.owner ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner) : log.addedByName}
																			modifiedBy={log.modifiedBy ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.modifiedBy) : log.addedByName}
																			handleEditactivity={(e) => { e.preventDefault(); }}
																			handleComplete={(e) => { e.preventDefault(); }}
																			completed={log.isDone}
																			activitylog={log}
																			handleDeleteactivity={(e) => { e.preventDefault(); }}
																			scheduled={true}
																			isEditable={log.isEditable}
																			callDuration={log.callDuration}
																			callStatus={log.callStatus}
																			handlechangelog={(e) => { e.preventDefault(); }}
																			handlesavelog={(e) => { e.preventDefault(); }}
																			handlecancellog={(e) => { e.preventDefault(); }}
																			textareaRef={textareaRef}
																			handleDateChangelog={(e) => { e.preventDefault(); }}
																			completedTab={completedTab}
																			logLoader={false}
																			dealersettings={props.dealersettings}
																			handleUnlickEmail={(e) => { e.preventDefault(); }}
																			handleEDMsPreviewEmail={(e) => { e.preventDefault(); }}
																			handlePreviewEmail={handlePreviewEmail}
																			handleReplylog={(e) => { e.preventDefault(); }}
																			contact={selectedCustomer}
																			enquiry={selectedEnquiry}
																			tradeinPro={selectedTIP}
																			service={selectedService}
																			companyView={props.companyView}
																			history={history}
																			hideMoreOption={true}
																			originType={props.originType}
																		>
																		</ActivityTag>
																		</div>
																	})
																}
															</div>
														</>
														:
														<>
															<div className="text-center p-5">
																<p><Translate text={'You have no linked activities.'} /></p>
															</div>
														</>

												}


											</div>
										</div>
									)}

							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>

					{
						(!_.isEmpty(oldactivityLog) && oldactivityLog.type === CommonHelper.getActivityType('activity', props.originType) && oldactivityLog.isDone === true) ? (
							<div className="vehicle-status-alert alert-user mb-1">
								<b className="alert-link"><Translate text={'Completed'} /> :{' '}</b>
								{
									(oldactivityLog.completedDate && oldactivityLog.completedBy)
										?
										<>
											{moment.unix(oldactivityLog.completedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), oldactivityLog.completedBy)}
										</>
										:
										<>
											{moment.unix(oldactivityLog.modifiedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), oldactivityLog.modifiedBy)}
										</>
								}
								{
									(!_.isEmpty(oldactivityLog) &&
										oldactivityLog.type === CommonHelper.getActivityType('activity', props.originType) &&
										oldactivityLog.isDone === true &&
										oldactivityLog.subType && oldactivityLog.subType === 'call' &&
										oldactivityLog.activityOutcome &&
										props.dealersettings &&
										props.dealersettings.client &&
										props.dealersettings.client.settings &&
										!_.isEmpty(props.dealersettings.client.settings.activityOutcome) &&
										props.dealersettings.client.settings.activityOutcome.filter(a => a.value === oldactivityLog.activityOutcome).length
									) ? (
										<div className="mt-1 w-100 d-inline-block">
											<b className="alert-link"><Translate text={'Activity Outcome'} /> : </b>{props.dealersettings.client.settings.activityOutcome.filter(a => a.value === oldactivityLog.activityOutcome)[0].name}
										</div>
									) : (<></>)
								}

							</div>
						) : (<></>)
					}

					{
						(!_.isEmpty(oldactivityLog) && oldactivityLog.addedDate)
							?
							<>
								<div className="activity-pop-info alert-activity-created mb-1">
									<b className="alert-link"> <Translate text={'Created'} /> :</b>
									{moment.unix(oldactivityLog.addedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), oldactivityLog.addedBy, oldactivityLog.addedByName)}
								</div>
							</>
							:
							<>
							</>
					}
					{
						!_.isEmpty(rosterMsg) ? (
							<div className="activity-pop-info alert-danger mb-1">
								<i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
							</div>
						) : (<></>)
					}
					{(() => {
						return !_.isEmpty(oldactivityLog) || !_.isEmpty(rosterMsg) ? (
							<div className='mt-3'></div>
						) : (
							<></>
						)
					})()}
					{
						!props.isNewActivity && activityLog && activityLog.contactID && isEditable
							?
							<>
								<div className="linked-activities-bottom">
									<button type="button"
										className="btn btn-default float-left mr-2"
										onClick={(e) => { handleAddLog(); }}
									>
										<i className="ico icon-add"></i> <Translate text={'Add Log'} />
									</button>
								</div>

							</>
							:
							<></>
					}

					{(() => {
						return <>
							{
								isEditable
									?
									<>
										{
											activityOldLog?.isDone ? (
												<div className='checkbox icheck-success  float-left checkbox-bg'>
													<input
														type='checkbox'
														id={`success-pop-${activityLog.documentID}`}
														name='isDone'
														onChange={e => {
															setActivitylog({
																...activityLog,
																isDone: e.target.checked,
															});
														}}
														checked={activityLog.isDone}
													/>
													<label htmlFor={`success-pop-${activityLog.documentID}`}>
														<Translate text={'markasdone'} />
													</label>
												</div>
											) : (
												<></>
											)
										}
										{
											(props.isNewActivity &&
												localStorage.defaultModule !== 'oem' &&
												enableCustShowNoShow &&
												//isInboundAccess &&
												//(selectedEnquiry && selectedEnquiry.pipeline !== 'LeadsBucket') &&
												(activityLog.subType === 'appointment' || (activityTypes && activityTypes.filter(e => e.value === activityLog.subType && e.appointment === true)[0])))
												?
												<div className='checkbox icheck-success float-left ml-3'>
													<input
														type='checkbox'
														id={`success-pop-inbound-${activityLog.documentID}`}
														name='inbound'
														onChange={e => {
															setActivitylog({
																...activityLog,
																inbound: e.target.checked,
															});
														}}
														checked={activityLog.inbound}
													/>
													<label htmlFor={`success-pop-inbound-${activityLog.documentID}`}>
														<Translate text={'Track Appointment'} />
													</label>
												</div>
												:
												<></>
										}
										{
											(!props.isNewActivity && !activityLog.inbound && !_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.deleteActivity)
												? (
													<button
														type='button'
														className='btn btn-red float-left ml-2'
														onClick={() => {
															deleteActivity();
														}}
													>
														{deleteLoader ? (
															<span
																className='spinner-border spinner-button mr-1'
																role='status'
																aria-hidden='true'
															></span>
														) : (
															<>
																<i className='ico icon-delete marker-delete'></i>
															</>
														)}
														<Translate text={'delete'} />
													</button>
												) : (
													<></>
												)}


										{
											(!props.isNewActivity && !Boolean(activityOldLog?.isDone)) ? <button
												type='button'
												className='btn btn-green float-right ml-2'
												onClick={e => {
													checkOutcomeAndSaveActivity(true)
												}}
											>
												{doneloader ? (
													<span
														className='spinner-border spinner-button mr-1'
														role='status'
														aria-hidden='true'
													></span>
												) : (
													<></>
												)}
												<Translate text={'Save as Done'} />
											</button> : (<></>)
										}


										<button
											type='button'
											className='btn btn-primary float-right ml-2'
											onClick={e => checkOutcomeAndSaveActivity()}
										>
											{loader ? (
												<span
													className='spinner-border spinner-button mr-1'
													role='status'
													aria-hidden='true'
												></span>
											) : (
												<></>
											)}
											<Translate text={'save'} />
										</button>
										{
											mandScheduleNextActivity
												?
												<></>
												:
												<><button
													type='button'
													className='btn btn-default float-right'
													onClick={() => props.handleClose()}
												>
													<Translate text={'cancel'} />
												</button></>
										}

									</>
									:
									<>
									</>
							}
						</>
					})()}

				</Modal.Footer>
			</Modal>
			<PopUpModal show={showOutcomeModal.show}>
				<ActivityStatus
					show={showOutcomeModal.show}
					isMandatory={showOutcomeModal.isMandatory}
					activtyOutcomes={showOutcomeModal.activtyOutcomes}
					handleClose={handleCloseOutcomeModal}
					title={'Select Activity Outcome'}
					dealersettings={props.dealersettings}
				/>
			</PopUpModal>
			<PopUpModal show={stockModel.show}>
				<AddStock
					docID={stockModel.ID}
					show={stockModel.show}
					clsActive={stockModel.clsActive}
					handleClose={handleStockClose}
					title={stockModel.title}
					enableSale={true}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
				></AddStock>
			</PopUpModal>

			<PopUpModal show={contactPopover.show}>
				<Contacts
					show={contactPopover.show}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					handleContactClose={handleContactClose}
					showAdd={false}
				/>
			</PopUpModal>
			<PopUpModal show={enquiryPopover.show}>
				<PopoverEnquiryList
					show={enquiryPopover.show}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					handleEnquiryClose={handleEnquiryClose}
					contactID={contactID}
				/>
			</PopUpModal>
			<PopUpModal show={tradeinProPopover.show}>
				<TradeinList
					show={tradeinProPopover.show}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					handleClose={handleTIPClose}
					contactID={contactID}
				/>
			</PopUpModal>
			<PopUpModal show={servicePopover.show}>
				<ServiceList
					show={servicePopover.show}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					handleClose={handleServiceClose}
					contactID={contactID}
				/>
			</PopUpModal>
			<PopUpModal show={stockPopover.show}>
				<Stocks
					show={stockPopover.show}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					handleClose={handleStockClose}
					handleAdd={handleAddStock}
					showAdd={true}
					displayPrice={false}
					fromActivity={true}
				/>
			</PopUpModal>

			<PopUpModal show={linkLogModel.show}>
				<AddActivityLog
					linkActivityID={activityLog ? activityLog.documentID : ''}
					show={linkLogModel.show}
					title={'Add Log'}
					enquiry={selectedEnquiry}
					tradeinPro={selectedTIP}
					service={selectedService}
					contact={selectedCustomer}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					history={history}
					handleClose={handleAddLogClose}
					originType={props.originType}
				></AddActivityLog>
			</PopUpModal>

			<PopUpModal show={showPreviewModel}>
				<EmailPreview
					show={showPreviewModel}
					handleClose={handleModelClose}
					title={popheader}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					emailLog={emailLog}
					contact={
						!_.isEmpty(selectedEnquiry)
							? selectedEnquiry.contact
							: !_.isEmpty(selectedTIP)
								? selectedTIP.contact
								: !_.isEmpty(selectedService)
									? selectedService.contact
									: !_.isEmpty(selectedCustomer)
										? selectedCustomer
										: null
					}
					history={history}
					enquiry={!_.isEmpty(selectedEnquiry) ? selectedEnquiry : null}
					tradeinPro={selectedTIP}
					service={selectedService}
				/>
			</PopUpModal>

			<PopUpModal show={showLogModel}>
				<ViewActivityLog
					show={showLogModel}
					title={'logs'}
					enquiry={selectedEnquiry}
					tradeinPro={selectedTIP}
					service={selectedService}
					contact={selectedCustomer}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					history={history}
					handleClose={(e) => { setShowLogModel(false) }}
					originType={props.originType}
				></ViewActivityLog>
			</PopUpModal>

			<PopUpModal show={showNotesHistoryModel}>
				<ViewNotesHistory
					activityID={activityLog.documentID}
					show={showNotesHistoryModel}
					title={'Notes History'}
					activityLog={activityLog}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					history={history}
					handleClose={(e) => { setShowNotesHistoryModel(false) }}
					originType={props.originType}
				></ViewNotesHistory>
			</PopUpModal>


		</>
	);
};

export default AddActivity;
