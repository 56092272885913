import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from "moment";
import { reportVM, reportGroupBy, reportDynColumns, reportDynFields, calculateOptions, datefilterType, visibilities, columnTypes, dataQualityOptions, modelGroupTypes, levelOptions, testdriveOptions } from '../../reports/viewModel'
import Translate from '../../../constants/translate';
import { InputText, ReactSelect, ReactMultiSelect, TableView, BarChart, PieChart, LineChart, PopUpModal, InputCheckBox } from '../../../components'
import CommonHelper from '../../../services/common';
import _images from '../../../images'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import RearrangeColumns from '../../reports/rearrangeColumns'
import NumberFormat from 'react-number-format';
import CustomColumns from '../../reports/customColumns';

const AddDynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 150) })
    const [report, setReport] = useState(!_.isEmpty(props.report) ? props.report : {});
    const [oldReport, setOldRreport] = useState()
    const newReport = !_.isEmpty(props.report) && !props.report.name.includes('- Copy') ? false : true
    const [errorFields, setErrorFields] = useState({});
    const [clientOptions, setClientOptions] = useState([])
    const [pipelineOptions, setPipelineOptions] = useState([])
    const [loader, setLoader] = useState(false)
    const [groupOptions, setGroupoptions] = useState([]);
    const [defaultGroup, setDefaultGroup] = useState(!_.isEmpty(props.report) ? props.report.groupBy : '');
    const [reportData, setReportData] = useState([]);
    const [reportFields, setReportFields] = useState(reportDynFields)
    const [showModel, setShowmodel] = useState(false)
    const [modelType, setModelType] = useState()
    const [popHeader, setPopHeader] = useState()
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [dymamicCols, setDymamicColumns] = useState([]);
    const [loadSettings, setLoadSettings] = useState(false)
    const [displayOptions, setDisplayOptions] = useState();
    const [integrations, setIntegrations] = useState();
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);
    const [customColumns, setCustomColumns] = useState({ show: false })
    const [regiongroupOptions, setRegionGroupOptions] = useState([])
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const enqStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 150),
                tblWidth: (window.innerWidth - 695)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.report)) {
            let newReportVM = Object.assign({}, reportVM);
            newReportVM.documentID = window.firebase.firestore().collection(`reports`).doc().id;
            newReportVM.addedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.addedBy = localStorage.uid;
            newReportVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.modifiedBy = localStorage.uid;
            newReportVM.owner = localStorage.uid;
            newReportVM.isDeleted = false;
            newReportVM.reportType = 'table';
            newReportVM.groupBy = '';
            newReportVM.groupSubtype = '';
            newReportVM.columns = '';
            newReportVM.target = '';
            newReportVM.visibility = 'private';
            newReportVM.clientIDs = [];
            newReportVM.pipeline = [];
            newReportVM.displayBy = 'client';
            newReportVM.level = 'individual';
            if (props.dealersettings.level === 'oem') {
                newReportVM.oemID = props.dealersettings.oemID;
            }
            else if (props.dealersettings.level === 'region') {
                newReportVM.regionID = props.dealersettings.regionID;
            }
            else if (props.dealersettings.level === 'group') {
                newReportVM.groupID = props.dealersettings.groupID;
            }
            newReportVM.settingsID = props.dealersettings.oemID;
            setReport(newReportVM)
        }
        else {
            setOldRreport(props.report)
        }
    }, [])

    useEffect(() => {
        async function getAllClientIntegrations() {
            let promise = [];
            let _options = [];
            props.dealersettings.clients.forEach(client => {
                promise.push(window.firebase.firestore().collection(`clients/${client.id}/integrations`)
                    .where('inbound', '==', true)
                    .where('active', '==', true)
                    .where('enabled', '==', true)
                    .get());
            })
            const snapshot = await Promise.all(promise);
            snapshot.forEach((snap) => {
                snap.docs.forEach(doc => {
                    if (!_options.some(e => e.value === doc.data().type))
                        _options.push({
                            label: CommonHelper.getNameByValue(props.dealersettings.integrations, doc.data().type, doc.data().type),
                            value: doc.data().type,
                        })
                });
            });
            setIntegrations(_options);
        }
        getAllClientIntegrations();
    }, [])

    useEffect(() => {
        let _leadSource = []
        let _sourceFields = []
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Enquiry Type</span></>,
                        searchlabel: `${rec.name} Enquiry Type`,
                        value: `enqtype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `enqtype-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Origin</span></>,
                        searchlabel: `${rec.name} Origin`,
                        value: `origin-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `origin-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Lead Source</span></>,
                        searchlabel: `${rec.name} Lead Source`,
                        value: `leadSource-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lead Source',
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `leadSource-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Lost Reason</span></>,
                        searchlabel: `${rec.name} Lost Reason`,
                        value: `lostReason-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lost Reason',
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `lostReason-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Activity Outcome</span></>,
                        searchlabel: `${rec.name} Activity Outcome`,
                        value: `outcome-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Activity Outcome',
                        type: 'activity'
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `outcome-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Call Status</span></>,
                        searchlabel: `${rec.name} Call Status`,
                        value: `callstatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Call Status',
                        type: 'log'
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `callstatus-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Enquiry Status</span></>,
                        searchlabel: `${rec.name} Enquiry Status`,
                        value: `enqstatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                    if (rec.afterSold === true) {
                        _leadSource.push({
                            label: <>{rec.name} Conv Ratio<span className="user-role-span">Enquiry Status</span></>,
                            searchlabel: `${rec.name} Conv Ratio Enquiry Status`,
                            value: `enqstatus-${rec.value}Conv`,
                            name: `${rec.name} Conv. Ratio`,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['percentage'],
                            percentage: true,
                            color: rec.color,
                            sourceType: 'Enquiry Status'
                        })
                        _sourceFields.push({
                            name: `${rec.name} Conv. Ratio`,
                            value: `enqstatus-${rec.value}Conv`,
                            flex: 1
                        })
                    }
                    _sourceFields.push({
                        name: rec.name,
                        value: `enqstatus-${rec.value}`,
                        flex: 1
                    })

                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Activity Type`,
                        value: `acttype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                    _leadSource.push({
                        label: <>{rec.name} Done<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Activity Type`,
                        value: `acttypedone-${rec.value}`,
                        name: `${rec.name} Done`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        percentage: true,
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `acttype-${rec.value}`,
                        flex: 1
                    })
                    _sourceFields.push({
                        name: `${rec.name} Done`,
                        value: `acttypedone-${rec.value}`,
                        flex: 1
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Finance Status</span></>,
                        searchlabel: `${rec.name} Finance Status`,
                        value: `financestatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Finance Status',
                    })
                    _sourceFields.push({
                        name: rec.name,
                        value: `financestatus-${rec.value}`,
                        flex: 1
                    })

                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        _leadSource.push({
                            label: <>{rec}<span className="user-role-span">{item.name}</span></>,
                            searchlabel: `${rec} ${item.name}`,
                            value: `${item.type};${item.value};${rec}`,
                            name: item.name,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['count'],
                            color: '#4967CC',
                            sourceType: item.name,
                        })
                        _sourceFields.push({
                            name: rec,
                            value: `${item.type};${item.value};${rec}`,
                            flex: 1
                        })
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            _leadSource.push({
                label: rec,
                searchlabel: rec,
                value: rec,
                name: rec.name,
                view: [],
                calculationType: ['count'],
                color: '#4967CC',
                sourceType: 'Persistency'
            })
            _sourceFields.push({
                name: rec,
                value: rec,
                flex: 1
            })
        })
        if (props.dealersettings.isPorsche === true) {
            _leadSource.push({
                label: 'Leads Created in C@P',
                searchlabel: 'Leads Created in C@P',
                value: 'newCAP',
                name: 'Leads Created in C@P',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Lost from Enquiries',
                searchlabel: 'Lost from Enquiries',
                value: 'lostEnq',
                name: 'Lost from Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Assigned Enquiries',
                searchlabel: 'Assigned Enquiries',
                value: 'allocated',
                name: 'Assigned Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Outdated',
                searchlabel: 'Outdated',
                value: 'outdated',
                name: 'Outdated',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _sourceFields.push({
                name: 'Outdated',
                value: 'outdated',
                flex: 1
            })
            _sourceFields.push({
                name: 'Assigned Enquiries',
                value: 'allocated',
                flex: 1
            })
            _sourceFields.push({
                name: 'Leads Created in C@P',
                value: 'newCAP',
                flex: 1
            })
            _sourceFields.push({
                name: 'Lost from Enquiries',
                value: 'lostEnq',
                flex: 1
            })
        }
        // if (!_.isEmpty(props.report)) {
        //     setstageColumns(props.report.pipeline, _leadSource, _sourceFields)
        // }
        // else {
        setReportColumns([...reportColumns.map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.label} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : { ...rec }
        }), ..._leadSource]);
        setReportFields([...reportFields.map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : { ...rec }
        }), ..._sourceFields]);
        setLoadSettings(true);
        // }

        const enquiryOptions = [];
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && (a.type === "select" || a.type === "multiselect"))
                .forEach(rec => {
                    enquiryOptions.push({
                        label: rec.name,
                        value: `${rec.type}-${rec.value}`,
                        displayName: rec.name,
                        displayValue: rec.name.toLowerCase(),
                        tableHeader: rec.name,
                    })
                })
        if (enquiryOptions.length > 0) {
            setReportGroupBy([...reportGroupBy, ...enquiryOptions]);
        }

    }, [])

    useEffect(() => {
        let option = [{ label: `${(_.isEmpty(report.level) || report.level === 'individual') ? 'Client' : `${CommonHelper.autoCaps(report.level)}`}`, value: 'client' }];
        if (!_.isEmpty(defaultGroup)) {
            option.push({ label: _.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).label : defaultGroup, value: defaultGroup });
        }
        setDisplayOptions(option)
    }, [defaultGroup])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return
        let _clients = [];
        if (report.level === 'region') {
            _.filter(props.dealersettings.regions, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients.map(rec => { return rec }), 'region')), v.id) >= 0).forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        else if (report.level === 'group') {
            _.filter(props.dealersettings.groups, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients.map(rec => { return rec }), 'group')), v.id) >= 0).forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        else {
            props.dealersettings.clients.filter(client =>
                (report.filterRegion ? client.region === report.filterRegion : true) &&
                (report.filterSubregion ? client.subregion === report.filterSubregion : true)
            ).forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        if (_clients.length > 1) {
            _clients = [{ value: 'all', label: 'All' }, ..._clients]
        }
        setClientOptions(_clients)
    }, [report.level, report.filterRegion, report.filterSubregion])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return
        let _clients = [];
        if (report.createdLevel === 'region') {
            _.filter(props.dealersettings.regions, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients.map(rec => { return rec }), 'region')), v.id) >= 0).forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        else if (report.createdLevel === 'group') {
            _.filter(props.dealersettings.groups, (v) => _.indexOf(_.uniq(_.map(props.dealersettings.clients.map(rec => { return rec }), 'group')), v.id) >= 0).forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        setRegionGroupOptions(_clients)
    }, [report.createdLevel])

    useEffect(() => {
        if (_.isEmpty(report) || _.isEmpty(report.reportType) || _.isEmpty(report.columns) || _.isEmpty(groupOptions)) {
            setReportData([])
            return
        }

        if (_.isArray(report.columns) && report.columns.length > 0) {
            setReportFields([
                ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                    return {
                        ...rec,
                        default: true
                    }
                }),
                ...report.columns.map(rec => {
                    return {
                        ..._.find(reportFields, { value: rec }),
                        default: true
                    }
                }),
                ..._.filter(reportFields, (v) => _.indexOf(report.columns, v.value) < 0 && v.value !== 'name').map(rec => {
                    return {
                        ...rec,
                        default: false
                    }
                }),
            ])
        }
        else if (report.columns) {
            setReportFields([
                ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                    return {
                        ...rec,
                        default: true
                    }
                }),
                {
                    ..._.find(reportFields, { value: report.columns }),
                    default: true
                },
                ..._.filter(reportFields, (v) => _.indexOf([report.columns], v.value) < 0 && v.value !== 'name').map(rec => {
                    return {
                        ...rec,
                        default: false
                    }
                }),
            ])
        }
        let _reportData = []
        if (report.displayBy === 'user' || report.displayBy === 'enqAddedBy') {
            _.filter(_.uniqBy(props.groupUsers, 'id'), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.id) >= 0))
                .map(rec => {
                    return _reportData.push({
                        id: rec.id,
                        name: rec.name,
                        profileImage: CommonHelper.showUserAvatar(rec.profileImage, rec.name),
                        ...getRandomValues()
                    });
                });
        }
        else if ((report.displayBy === 'enquiryTypes' || report.displayBy === 'campaigns' || report.displayBy === 'origins' || report.displayBy === 'salesType' || report.displayBy === 'lostReasons' || defaultGroup === 'enquiryStatus' || defaultGroup === 'pipelines')
            && !_.isEmpty(props.dealersettings.client.settings[defaultGroup])) {
            _.filter(props.dealersettings.client.settings[defaultGroup], (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0) && v.active === true)
                .map(rec => {
                    return _reportData.push({
                        id: rec.value,
                        name: rec.name,
                        ...getRandomValues()

                    });
                })

        }
        else if (report.displayBy === 'model' && !_.isEmpty(props.dealersettings.client.brands)) {
            _.filter(props.dealersettings.client.brands, (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0))
                .map(make => {
                    _.uniqBy(make.models.filter(a => (!_.isEmpty(report.model) ? report.model.includes(a.value) : true)
                        && (!_.isEmpty(report.group) ? report.group.includes(a.group) : true)
                        && (!_.isEmpty(report.modelType) ? report.modelType.includes(a.type) : true)
                    ), report.modelGroupBy === 'model' ? 'value' : report.modelGroupBy).map(model => {
                        return _reportData.push({
                            id: make.value + '-' + model.value,
                            name: report.modelGroupBy === 'group' ? model.group : report.modelGroupBy === 'type' ? model.type : report.modelGroupBy === 'model' ? model.name : make.name + ' ' + model.name + (!_.isEmpty(model.type) ? ' ' + model.type : ''),
                            ...getRandomValues()
                        });
                    })
                })
        }
        else if (report.displayBy === 'dateFilter') {
            var dates = CommonHelper.getAllDaysofWeek(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0)
            if (report.groupSubtype === 'weekly') { dates = CommonHelper.getAllWeeks(moment().startOf('month'), moment().endOf('month'), moment().day(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0).day()) }
            else if (report.groupSubtype === 'monthly') { dates = CommonHelper.getAllMonths(moment().startOf('year'), moment().endOf('year')) }

            dates.map(date => {
                return _reportData.push({
                    id: `${report.groupSubtype}-${moment(date[0]).format('DD/MM/YYYY')}`,
                    name: report.groupSubtype === 'daily' ? moment(date[0]).format('DD/MM/YYYY') : report.groupSubtype === 'monthly' ? moment(date[0]).format('MMM YYYY') : `${moment(date[0]).format('DD MMM')}-${moment(date[date.length - 1]).format('DD MMM YYYY')}`,
                    ...getRandomValues()
                });
            })
        }
        else if (report.displayBy === 'dataQuality' || report.displayBy === 'inboundSource') {
            _.filter(groupOptions.filter(a => a.value !== 'all'), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0)).map(data => {
                return _reportData.push({
                    id: data.value,
                    name: data.label,
                    ...getRandomValues()
                });
            })
        }
        else if (report.level === 'group' || report.level === 'region') {
            _.filter(props.dealersettings[`${report.level}s`], (v) => _.indexOf(report[`${report.level}IDs`], v.id) >= 0)
                .map(rec => {
                    return _reportData.push({
                        id: rec.id,
                        name: rec.name,
                        profileImage: CommonHelper.showUserAvatar(rec.profileImage, rec.name),
                        ...getRandomValues(true)
                    });
                });
        }
        else {
            _.filter(props.dealersettings.clients, (v) => _.indexOf(report.clientIDs, v.id) >= 0)
                .map(rec => {
                    return _reportData.push({
                        id: rec.id,
                        name: rec.name,
                        profileImage: CommonHelper.showUserAvatar(rec.profileImage, rec.name),
                        ...getRandomValues(true)
                    });
                });
        }
        _reportData = _reportData.map(rec => {
            return {
                ...rec,
                carryover: Math.floor(Math.random() * 100),
                total: (rec.newLeads + rec.exisitngLeads),
                walkintestdrive: Math.floor(Math.random() * 100) + '%',
                walkinsold: Math.floor(Math.random() * 100) + '%',
                appointmenttestdrive: Math.floor(Math.random() * 100) + '%',
                appointmentsold: Math.floor(Math.random() * 100) + '%',
                testdrivetotestdrive: Math.floor(Math.random() * 100) + '%',
                testdriveConv: Math.floor(Math.random() * 100) + '%',
                tradeinwonConv: Math.floor(Math.random() * 100) + '%',
                wonConv: Math.floor(Math.random() * 100) + '%',
                scheduledConv: Math.floor(Math.random() * 100) + '%',
                showConv: Math.floor(Math.random() * 100) + '%',
                noshowConv: Math.floor(Math.random() * 100) + '%',
                convertedConv: Math.floor(Math.random() * 100) + '%',
                newLeadsConv: Math.floor(Math.random() * 100) + '%',
                exisitngLeadsConv: Math.floor(Math.random() * 100) + '%',
                totalConv: Math.floor(Math.random() * 100) + '%',
                // walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) > 100 ? '100%' : Math.round((100 / rec.walkin) * rec.testdrive) + '%' : '0%',
                // walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) > 100 ? '100%' : Math.round((100 / rec.walkin) * rec.won) + '%' : '0%',
                // appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.testdrive) > 0 ? '100%' : Math.round((100 / rec.appointment) * rec.testdrive) + '%' : '0%',
                // appointmentsold: rec.appointment > 0 ?  Math.round((100 / rec.appointment) * rec.won)  > 0 ? '100%' : Math.round((100 / rec.appointment) * rec.won) + '%' : '0%',
                // testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.testdrive) > 0 ? '100%' : Math.round((100 / rec.testdriveActivity) * rec.testdrive) + '%' : '0%',
                // testdriveConv: (rec.newLeads + rec.exisitngLeads) > 0 ? Math.round((100 / (rec.newLeads + rec.exisitngLeads)) * rec.testdrive) > 0 ? '100%' : Math.round((100 / (rec.newLeads + rec.exisitngLeads)) * rec.testdrive) + '%' : '0%',
                // tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) > 0 ? '100%' : Math.round((100 / rec.tradein) * rec.tradeinwon) + '%' : '0%',
                // wonConv: (rec.newLeads + rec.exisitngLeads) > 0 ? Math.round((100 / (rec.newLeads + rec.exisitngLeads)) * rec.won) > 0 ? '100%' : Math.round((100 / (rec.newLeads + rec.exisitngLeads)) * rec.won) + '%' : '0%',
            }
        })
        setReportData(_reportData)
    }, [report.groupBy, report.columns, report.target, report.displayBy, groupOptions, report.model, report.group, report.modelType, report.modelGroupBy, report.clientIDs, report.regionIDs, report.groupIDs])

    const getRandomValues = (clientView) => {
        return {
            newLeads: Math.floor(Math.random() * 50),
            exisitngLeads: Math.floor(Math.random() * 50),
            open: Math.floor(Math.random() * 100),
            won: Math.floor(Math.random() * 100),
            wonTD: Math.floor(Math.random() * 100),
            lost: Math.floor(Math.random() * 100),
            prospectLost: Math.floor(Math.random() * 100),
            leadLost: Math.floor(Math.random() * 100),
            totalLost: Math.floor(Math.random() * 100),
            delivered: Math.floor(Math.random() * 100),
            walkin: Math.floor(Math.random() * 100),
            newwalkin: Math.floor(Math.random() * 100),
            repeatwalkin: Math.floor(Math.random() * 100),
            testdrive: Math.floor(Math.random() * 100),
            inboundCall: Math.floor(Math.random() * 100),
            outboundCall: Math.floor(Math.random() * 100),
            totalCall: Math.floor(Math.random() * 100),
            messageLog: Math.floor(Math.random() * 100),
            emailLog: Math.floor(Math.random() * 100),
            noteLog: Math.floor(Math.random() * 100),
            appointment: Math.floor(Math.random() * 100),
            testdriveActivity: Math.floor(Math.random() * 100),
            callActivity: Math.floor(Math.random() * 100),
            mailActivity: Math.floor(Math.random() * 100),
            deliveryActivity: Math.floor(Math.random() * 100),
            target: report.target,
            tradein: Math.floor(Math.random() * 100),
            tradeininprogress: Math.floor(Math.random() * 50),
            tradeincompleted: Math.floor(Math.random() * 50),
            tradeinwon: Math.floor(Math.random() * 50),
            tradeinlost: Math.floor(Math.random() * 50),
            pendingLeads: Math.floor(Math.random() * 50),
            overallPending: Math.floor(Math.random() * 50),
            totalInbound: Math.floor(Math.random() * 50),
            convertedLeads: Math.floor(Math.random() * 50),
            show: Math.floor(Math.random() * 50),
            noshow: Math.floor(Math.random() * 50),
            appointmentScheduled: Math.floor(Math.random() * 100),
            testdriveScheduled: Math.floor(Math.random() * 100),
            callScheduled: Math.floor(Math.random() * 100),
            mailScheduled: Math.floor(Math.random() * 100),
            deliveryScheduled: Math.floor(Math.random() * 100),
            responseTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            minresponseTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 100), props.dealersettings.client),
            maxresponseTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            allocatedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            minallocatedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 100), props.dealersettings.client),
            maxallocatedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            contactedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            mincontactedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 100), props.dealersettings.client),
            maxcontactedTime: CommonHelper.timeformatfromSeconds(Math.floor(Math.random() * 500), props.dealersettings.client),
            quotes: Math.floor(Math.random() * 100),
            noActivity: Math.floor(Math.random() * 10),
            unactionedLeads: Math.floor(Math.random() * 10),
            noNotes: Math.floor(Math.random() * 10),
            noCalls: Math.floor(Math.random() * 10),
            noMessage: Math.floor(Math.random() * 10),
            allocated: Math.floor(Math.random() * 10),
            newCAP: Math.floor(Math.random() * 10),
            lostEnq: Math.floor(Math.random() * 10),
            outdated: Math.floor(Math.random() * 10),
            doneActivity: Math.floor(Math.random() * 100),
            overdueActivity: Math.floor(Math.random() * 100),
            statusModified: Math.floor(Math.random() * 100),
            formatquoteAmount: <NumberFormat
                thousandSeparator={true}
                prefix={currencySymbol}
                displayType={'text'}
                value={Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100}
            />,
            formatwonAmount: <NumberFormat
                thousandSeparator={true}
                prefix={currencySymbol}
                displayType={'text'}
                value={Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100}
            />,
            quoteAmount: `${currencySymbol}${Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100}`,
            wonAmount: `${currencySymbol}${Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100}`,
            // quoteAmount: Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100,
            // wonAmount: Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100,
            ...getLeadSourceCount(clientView)
        };
    }

    const getLeadSourceCount = (clientView) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: Math.floor(Math.random() * 100)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: Math.floor(Math.random() * 100),
                        [`enqstatus-${rec.value}Conv`]: Math.floor(Math.random() * 50) + '%',
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && !Boolean(a.default))
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`acttype-${rec.value}`]: Math.floor(Math.random() * 100),
                        [`acttypedone-${rec.value}`]: Math.floor(Math.random() * 50),
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: Math.floor(Math.random() * 100),
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: Math.floor(Math.random() * 100),
                        }
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            leadSourceData = {
                ...leadSourceData,
                [rec]: Math.floor(Math.random() * 100)
            }
        })
        if (clientView) {
            let cols = [];
            if (report.groupBy === 'user' || report.groupBy === 'enqAddedBy') {
                _.filter(_.uniqBy(props.groupUsers, 'id'), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.id) >= 0))
                    .forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${report.groupBy}-${rec.value}`]: Math.floor(Math.random() * 50)
                        };
                        cols.push({
                            value: `${report.groupBy}-${rec.value}`,
                            name: rec.name
                        });
                    })
            }
            else if ((report.groupBy === 'enquiryTypes' || report.groupBy === 'campaigns' || report.groupBy === 'origins' || report.groupBy === 'salesType' || report.groupBy === 'lostReasons' || report.groupBy === 'pipelines')
                && !_.isEmpty(props.dealersettings.client.settings[defaultGroup])) {
                _.filter(props.dealersettings.client.settings[defaultGroup], (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0) && v.active === true)
                    .forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${report.groupBy}-${rec.value}`]: Math.floor(Math.random() * 50)
                        }
                        cols.push({
                            value: `${report.groupBy}-${rec.value}`,
                            name: rec.name
                        });
                    })

            }
            else if (report.groupBy === 'model' && !_.isEmpty(props.dealersettings.client.brands)) {
                _.filter(props.dealersettings.client.brands, (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0))
                    .forEach(make => {
                        make?.models && make.models.forEach(model => {
                            leadSourceData = {
                                ...leadSourceData,
                                [`${report.groupBy}-${make.value}-${model.value}`]: Math.floor(Math.random() * 50)
                            }
                            cols.push({
                                value: `${report.groupBy}-${make.value}-${model.value}`,
                                name: `${make.value} ${model.value}`
                            });
                        })

                    })
            }
            else if (report.groupBy === 'dateFilter') {
                var dates = CommonHelper.getAllDaysofWeek(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0)
                if (report.groupSubtype === 'weekly') { dates = CommonHelper.getAllWeeks(moment().startOf('month'), moment().endOf('month'), moment().day(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0).day()) }
                else if (report.groupSubtype === 'monthly') { dates = CommonHelper.getAllMonths(moment().startOf('year'), moment().endOf('year')) }

                dates.forEach(date => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${report.groupBy}-${report.groupSubtype}-${moment(date[0]).format('DD/MM/YYYY')}`]: Math.floor(Math.random() * 50)
                    }
                    cols.push({
                        value: `${report.groupBy}-${report.groupSubtype}-${moment(date[0]).format('DD/MM/YYYY')}`,
                        name: report.groupSubtype === 'daily' ? moment(date[0]).format('DD/MM/YYYY') : report.groupSubtype === 'monthly' ? moment(date[0]).format('MMM YYYY') : `${moment(date[0]).format('DD MMM')}-${moment(date[date.length - 1]).format('DD MMM YYYY')}`
                    });
                })
            }
            else if (report.groupBy === 'dataQuality' || report.groupBy === 'inboundSource') {
                _.filter(groupOptions.filter(a => a.value !== 'all'), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0))
                    .forEach(data => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${report.groupBy}-${data.value}`]: Math.floor(Math.random() * 50)
                        }
                        cols.push({
                            value: `${report.groupBy}-${data.value}`,
                            name: data.label
                        });
                    })
            }
            setDymamicColumns(cols);
        }
        return leadSourceData;
    }

    const getLostSubReasons = (rec) => {
        let _list = [];
        if (report.groupBy === 'lostReasons' && rec.subList) {
            CommonHelper.arrLimit(rec.subList, 5).forEach(el => {
                _list.push({
                    id: el.value,
                    name: el.name,
                    count: Math.floor(Math.random() * 10)
                })
            })
        }
        return _list;
    }

    const handleSavesettings = (fields) => {
        setReport({
            ...report,
            ['columns']: fields.filter(a => a !== 'name')
        })
    }

    useEffect(() => {
        if (_.isEmpty(defaultGroup))
            return
        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

        const salesroleIDs = props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.roles
                .filter(e => e.permissions && !Boolean(e.permissions.disableToAddEnquiry))
                .map(r => { return r.documentID })
                .join(',');
        let options = [];
        if (!ownerDashboard && defaultGroup !== 'dateFilter' && defaultGroup !== 'pipelines') {
            options.push({ value: 'all', label: 'All' })
        }
        if (defaultGroup === 'user' || defaultGroup === 'enqAddedBy') {
            props.groupUsers.filter(a => Boolean(a.superAdmin) === false
                && (salesroleIDs.length > 0 ? _.includes(salesroleIDs, a.roleID) : true)).forEach(rec => {
                    if (!options.some(e => e.value === rec.id)) {
                        options.push({
                            value: rec.id,
                            searchlabel: rec.name,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            clientIDs: rec.clientIDs,
                            label: (
                                <div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                    {rec.name}
                                </div>
                            ),
                        });
                    }
                });
        }
        else if ((defaultGroup === 'enquiryTypes' || defaultGroup === 'campaigns' || defaultGroup === 'origins' || defaultGroup === 'salesType' || defaultGroup === 'lostReasons' || defaultGroup === 'enquiryStatus' || defaultGroup === 'pipelines')
            && !_.isEmpty(props.dealersettings.client.settings[defaultGroup])) {
            props.dealersettings.client.settings[defaultGroup].filter(a => a.active === true).map(rec => {
                return options.push({
                    value: rec.value,
                    searchlabel: rec.name,
                    label: defaultGroup === 'enquiryStatus' ? (<div className={`badge badge-pill ${rec.value === 'open' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>) : rec.name
                });
            })

        }
        else if (defaultGroup === 'model' && !_.isEmpty(props.dealersettings.client.brands)) {
            props.dealersettings.client.brands.map(rec => {
                return options.push({
                    value: rec.value,
                    label: rec.name
                });
            })
        }
        else if (defaultGroup === 'dateFilter') {
            datefilterType.map(rec => {
                return options.push(rec);
            })
        }
        else if (defaultGroup === 'inboundSource') {
            if (_.isArray(integrations))
                options.push(...integrations);
            options.push({ label: 'Inbound Lead', value: 'LeadsBucket' });
            options.push({ label: 'Showroom Generated Leads', value: 'Sales' });
        }
        else if (defaultGroup === 'dataQuality') {
            options.push(...dataQualityOptions)
        }
        else if (defaultGroup.includes('select-') || defaultGroup.includes('multiselect-')) {
            props.dealersettings.client.settings.enquiryOptionsDF &&
                props.dealersettings.client.settings.enquiryOptionsDF.some(a => a.value === defaultGroup.replace(/select-|multiselect-/g, '')) &&
                props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.value === defaultGroup.replace(/select-|multiselect-/g, ''))[0].subList.map(rec => {
                    return options.push({
                        value: rec,
                        label: rec
                    });
                })
        }
        else if (defaultGroup === 'client') {
            props.dealersettings.clients.filter(a => report.level !== 'individual' ? report[`${report.level}IDs`].includes(a[report.level]) : true).forEach(rec => {
                return options.push({
                    label: rec.name,
                    value: rec.id
                })
            })
        }
        setGroupoptions(options);
    }, [integrations, defaultGroup, report.regionIDs, report.groupIDs]);

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return
        let _pipelines = [];
        props.dealersettings.client.settings.pipelines.filter(a => a.active === true).forEach(rec => {
            _pipelines.push({
                label: rec.name,
                value: rec.value,
                stages: rec.stages
            })
        })
        if (_pipelines.length > 1) {
            _pipelines = [{ value: 'all', label: 'All' }, ..._pipelines]
        }
        setPipelineOptions(_pipelines)
    }, [props.dealersettings.allPipelines])


    const handleReportTypeChange = (e, val) => {
        e.preventDefault();
        setReportData([])
        setReport({
            ...report,
            'columnType': null,
            'reportType': val,
            'columns': [],
            'tdCountBy': null,
            'groupSubtype': [],
            'calculationType': (val === 'target' || val === 'column') ? 'count' : '',
            'displayBy': val === 'table' ? '' : 'client'
        })
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')
            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'groupBy') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['groupSubtype']: e.value === 'pipelines' ?
                    report.pipeline.length === 1 && report.pipeline.some(a => a === 'LeadsBucket' || a === 'all') ? pipelineOptions.filter(a => a.value !== 'all').map(r => { return r.value }) : report.pipeline
                    : [],
                ['columns']: e.value === 'lostReasons' ? [] : report.columns,
                ['dynColumns']: e.value === 'lostReasons' ? [] : report.dynColumns ? report.dynColumns : [],
                ['displayBy']: report.reportType === 'table' ? '' : report.displayBy !== 'client' ? 'client' : report.displayBy
            })
        }
        else if (data.name === 'columnType' && e.value === 'persistency') {
            let col = [];
            Array.from(Array(16).keys())
                .map(r => { return r.toString() })
                .forEach(r => {
                    col.push(r);
                    if (r === '4' || r === '15') {
                        col.push(`${r}plus`);
                    }
                })
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['columns']: [...col, 'won']
            })
        }
        else if (data.name === 'calculationType' || data.name === 'columnType' || data.name === 'displayBy') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['columns']: [],
                ['dynColumns']: [],
                ['tdCountBy']: null,
            })
        }
        else if (data.name === 'modelGroupBy') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['model']: '',
                ['modelType']: '',
                ['group']: ''
            })
        }
        else if (data.name === 'level' || data.name === 'filterRegion' || data.name === 'filterSubregion') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['clientIDs']: [],
                ['regionIDs']: [],
                ['groupIDs']: []
            })
        }
        else if (data.name === 'columns') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['dynColumns']: [],
            })
        }
        else {
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })
        }


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleEditNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setReport({
                ...report,
                [name]: number !== '' ? Number(number) : number
            })
        }
    };

    const handleCheckedChange = (e) => {
        setReport({
            ...report,
            [e.target.name]: e.target.checked
        })
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        if (name === 'clientIDs' || name === 'regionIDs' || name === 'groupIDs') {
            if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
                value = clientOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
            }
            else {
                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                    value.push(data.value);
                })
            }
        }
        else if (name === 'pipeline') {
            if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
                value = pipelineOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
            }
            else {
                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                    value.push(data.value);
                })
            }
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
            if (value.length > 1 && value[value.length - 1] === 'all') {
                value = ['all']
            }

            if (value.length > 1 && value.some(item => item === 'all')) {
                value = value.filter(item => item !== 'all')
            }
        }


        if (name === 'groupSubtype') {
            setReport({
                ...report,
                [name]: value,
                ['model']: [],
                ['modelType']: [],
                ['group']: [],
                ['groupBy']: name === 'clientIDs' ? '' : report.groupBy,
            });
        }
        else if (name === 'columns') {
            setReport({
                ...report,
                [name]: value,
                ['dynColumns']: [],
                ['groupBy']: name === 'clientIDs' ? '' : report.groupBy,
            });
        }
        else {
            setReport({
                ...report,
                [name]: value,
                ['groupBy']: name === 'clientIDs' ? '' : report.groupBy,
                //['groupSubtype']: name === 'pipeline' ? '' : report.groupSubtype
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
        if (name === 'clientIDs') {
            //setstageColumns(value, [], [])
            setGroupoptions([]);
            setDefaultGroup('')
        }
    }

    const saveReport = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let mandatoryFields = ['name', 'groupSubtype', 'groupBy', 'columns']
        if (report.level === 'region' || report.level === 'group') {
            mandatoryFields.push(`${report.level}IDs`)
        }
        else {
            mandatoryFields.push('clientIDs')
        }
        if (report.reportType === 'target' || report.reportType === 'column') {
            mandatoryFields = [...mandatoryFields, 'calculationType']
        }
        for (let [key] of Object.entries(report)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(report[key].toString().trim())) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        if (report.level === 'region' || report.level === 'group') {
            report.clientIDs = props.dealersettings.clients.filter(a => report[`${report.level}IDs`].includes(a[report.level])).map(rec => {
                return rec.id
            })
        }
        if ((report.createdLevel === 'region' || report.createdLevel === 'group') && report.createdLevelID) {
            report[`${report.createdLevel}ID`] = report.createdLevelID
        } else {
            report.regionID = null;
            report.groupID = null;
        }
        setLoader(true)
        window.firebase.firestore().doc(`reports/${report.documentID}`).set({
            ...report,
            groupSubtype: _.isEmpty(report.groupSubtype) ? ['all'] : report.groupSubtype
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newReport === true ? 'Report added successfully' : 'Report updated successfully'), {
                    duration: 2000
                })
                props.setKey && props.setKey(report.documentID);
                props.handleClose(report, newReport);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const handleModelClose = () => {
        setShowmodel(false)
    }

    const handlePropsModelClose = () => {
        if (newReport || JSON.stringify(report) === JSON.stringify(oldReport)) {
            props.handleClose()
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'You want to discard changes.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then(result => {
                if (result.value) {
                    props.handleClose()
                }
            });
        }
    }

    // const setstageColumns = (pipeline, source, fields) => {
    //     let _leadSource = source
    //     let _sourceFields = fields
    //     if (pipeline.length === 1 && props.dealersettings.client.settings.pipelines.find(a => a.value === pipeline[0])) {
    //         props.dealersettings.client.settings.pipelines.find(a => a.value === pipeline[0]).stages.forEach(rec => {
    //             if (rec.value !== 'InboundLead') {
    //                 _leadSource.push({
    //                     label: <>{rec.name}<span className="user-role-span">Stage</span></>,
    //                     searchlabel: `${rec.name} Stage`,
    //                     value: `stage-${rec.value}`,
    //                     name: rec.name,
    //                     view: ['table', 'column', 'pie', 'target'],
    //                     calculationType: ['count'],
    //                     color: '#4967CC',
    //                     sourceType: 'Stage',
    //                 })
    //                 _sourceFields.push({
    //                     name: rec.name,
    //                     value: `stage-${rec.value}`,
    //                     flex: 1,
    //                     sourceType: 'Stage'
    //                 })
    //             }
    //         })
    //         setReportColumns([...reportColumns.filter(a => a.sourceType !== 'Stage'), ..._leadSource]);
    //         setReportFields([...reportFields.filter(a => a.sourceType !== 'Stage'), ..._sourceFields])
    //         setLoadSettings(true);
    //     }
    //     else {
    //         setReportColumns([...reportColumns.filter(a => a.sourceType !== 'Stage'), ..._leadSource]);
    //         setReportFields([...reportFields.filter(a => a.sourceType !== 'Stage'), ..._sourceFields])
    //         setLoadSettings(true);
    //     }
    // };

    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(report['filterRegion']) ? report['filterRegion'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    return _.isEmpty(report) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right"
                            onClick={() => handlePropsModelClose()}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen h-100">
                            <div className="container-fluid pl-0 ">
                                <div className="add-trigger-wrapper">

                                    <div className="add-trigger-leftmenu">
                                        <h3> <Translate text={'Report Type'} /></h3>
                                        <ul className="ul-nav" role="tablist">
                                            <li className="li-nav">
                                                <a href="#" className={`li-a-nav ${report.reportType === 'table' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'table')}>
                                                    <i className="ico ico icon-table-chart"></i>Table View</a>
                                            </li>
                                            <li className="li-nav">
                                                <a href="#" className={`li-a-nav ${report.reportType === 'column' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'column')}>
                                                    <i className="ico ico icon-bar-chart"></i>Column Chart</a>
                                            </li>
                                            <li className="li-nav">
                                                <a href="#" className={`li-a-nav ${report.reportType === 'pie' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'pie')}>
                                                    <i className="ico ico icon-pie-chart"></i>Pie Chart</a>
                                            </li>
                                            <li className="li-nav">
                                                <a href="#" className={`li-a-nav ${report.reportType === 'target' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'target')}>
                                                    <i className="ico ico icon-line-chart"></i>Target Report</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='add-trigger-column-wraper'>
                                        <div className="add-trigger-columns h-100" >
                                            <div className="add-report-column-left border-right">
                                                <div className="add-trigger-column-title">
                                                    <h3> <Translate text={'Configure Data'} /></h3>
                                                </div>
                                                <div className="trigger-form-wraper form-style">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label > Name <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={`name`}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={report.name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label> Report Type </label>
                                                            <ReactSelect
                                                                options={columnTypes.filter(a => a.value !== 'stock')}
                                                                name={"columnType"}
                                                                placeholder={`select column type`}
                                                                onChange={handleSelectChange}
                                                                value={report.columnType}
                                                                classNamePrefix={`basic-select`}
                                                                isSearchable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label >Pipeline</label>
                                                            <ReactMultiSelect
                                                                options={pipelineOptions}
                                                                name={"pipeline"}
                                                                placeholder={'select pipeline'}
                                                                onChange={(option) => handleReactMultiSelectChange(option, 'pipeline')}
                                                                value={report.pipeline}
                                                                classNamePrefix={` ${errorFields["pipeline"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                        {
                                                            props.dealersettings.level === 'oem' || props.dealersettings.level === 'region' ? (
                                                                <>
                                                                    <div className="form-group col-md-6">
                                                                        <label > <Translate text={'Region'} /></label>
                                                                        <ReactSelect
                                                                            options={getRegions()}
                                                                            name={'filterRegion'}
                                                                            placeholder={`select region`}
                                                                            onChange={handleSelectChange}
                                                                            value={report['filterRegion']}
                                                                            classNamePrefix={`basic-select`}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label > <Translate text={'Subregion'} /></label>
                                                                        <ReactSelect
                                                                            options={getSubregions()}
                                                                            name={'filterSubregion'}
                                                                            placeholder={`select subregion`}
                                                                            onChange={handleSelectChange}
                                                                            value={report['filterSubregion']}
                                                                            classNamePrefix={`basic-select`}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </>
                                                            ) : (<></>)
                                                        }

                                                        {/* <div className="form-group col-md-12">
                                                            <label > <Translate text={'Level'} /></label>
                                                            <ReactSelect
                                                                options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions.filter(e => e.value !== 'oem') :
                                                                    levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                                                name={"level"}
                                                                placeholder={'select level'}
                                                                onChange={handleSelectChange}
                                                                value={report.level}
                                                                classNamePrefix={`${errorFields["level"]} basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div> */}
                                                        <div className="form-group col-md-12">
                                                            <label >{report.level === 'individual' ? 'Clients' : `${CommonHelper.autoCaps(report.level)}s`} <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <ReactMultiSelect
                                                                options={clientOptions}
                                                                name={`${report.level === 'individual' ? 'clientIDs' : `${report.level}IDs`}`}
                                                                placeholder={'select client'}
                                                                onChange={(option) => handleReactMultiSelectChange(option, `${report.level === 'individual' ? 'clientIDs' : `${report.level}IDs`}`)}
                                                                value={report[`${report.level === 'individual' ? 'clientIDs' : `${report.level}IDs`}`]}
                                                                classNamePrefix={` ${errorFields[`${report.level === 'individual' ? 'clientIDs' : `${report.level}IDs`}`]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label> Displaying <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <ReactSelect
                                                                options={dynReportGroupBy.filter(a => (report.columnType === 'persistency' ?
                                                                    a.value !== 'inboundUser' && a.value !== 'enqAddedBy' && a.value !== 'enquiryStatus' && a.value !== 'pipelines' && a.value !== 'lostReasons' && a.value !== 'dataQuality' && (report.level === 'individual' ? a.value !== 'client' : true)
                                                                    : (report.level === 'individual' ? a.value !== 'client' : true)))}
                                                                name={"groupBy"}
                                                                placeholder={'select displaying'}
                                                                onChange={(e, data) => {
                                                                    if (e)
                                                                        setDefaultGroup(e.value)
                                                                    handleSelectChange(e, data)
                                                                }}
                                                                value={report.groupBy}
                                                                classNamePrefix={`${errorFields["groupBy"]} basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            {
                                                                (report.reportType === 'table') ? (<label>{_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayName : 'Segment by'} <span style={{ color: '#FF0000' }}>*</span></label>) :
                                                                    (<label>Segment by <span style={{ color: '#FF0000' }}>*</span></label>)
                                                            }
                                                            {
                                                                (report.groupBy === 'dateFilter') ? (
                                                                    <ReactSelect
                                                                        options={groupOptions}
                                                                        name={"groupSubtype"}
                                                                        placeholder={`select ${_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayValue : ''}`}
                                                                        onChange={handleSelectChange}
                                                                        value={report.groupSubtype}
                                                                        classNamePrefix={`${errorFields["groupSubtype"]} basic-select`}
                                                                    >
                                                                    </ReactSelect>
                                                                ) : (
                                                                    <ReactMultiSelect
                                                                        options={(defaultGroup === 'user' || defaultGroup === 'enqAddedBy') ? _.filter(groupOptions, (v) => _.intersection(report.clientIDs, v.clientIDs).length > 0 || v.value === 'all') : groupOptions}
                                                                        name={"groupSubtype"}
                                                                        placeholder={`select ${_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayValue : ''}`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'groupSubtype')}
                                                                        value={_.isArray(report.groupSubtype) ? report.groupSubtype : []}
                                                                        classNamePrefix={`${errorFields["groupSubtype"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                )
                                                            }

                                                        </div>
                                                        {
                                                            report.groupBy === 'model' ? (
                                                                <div className="form-group col-md-12">
                                                                    <label>Group By</label>
                                                                    <ReactSelect
                                                                        options={modelGroupTypes}
                                                                        name={"modelGroupBy"}
                                                                        placeholder={`select group by`}
                                                                        onChange={handleSelectChange}
                                                                        value={report.modelGroupBy}
                                                                        classNamePrefix={`${errorFields["modelGroupBy"]} basic-select`}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (report.modelGroupBy === 'group') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.group) && report.group.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Group'); setModelType('group'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Group</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.uniqBy(_.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.group,
                                                                                    value: model.group
                                                                                }
                                                                            }), 'value') : []
                                                                        }
                                                                        name={"group"}
                                                                        placeholder={`select group`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'group')}
                                                                        value={_.isArray(report.group) ? report.group : []}
                                                                        classNamePrefix={`${errorFields["group"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (report.modelGroupBy === 'type') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.modelType) && report.modelType.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Type'); setModelType('modelType'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Type</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.uniqBy(_.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.type,
                                                                                    value: model.type
                                                                                }
                                                                            }), 'value') : []
                                                                        }
                                                                        name={"modelType"}
                                                                        placeholder={`select type`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'modelType')}
                                                                        value={_.isArray(report.modelType) ? report.modelType : []}
                                                                        classNamePrefix={`${errorFields["modelType"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (!_.isEmpty(report.model) || report.modelGroupBy === 'model') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.model) && report.model.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Models'); setModelType('model'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Model</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.filter(props.dealersettings.client.brands, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.name,
                                                                                    value: model.value
                                                                                }
                                                                            }) : []
                                                                        }
                                                                        name={"model"}
                                                                        placeholder={`select model`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'model')}
                                                                        value={_.isArray(report.model) ? report.model : []}
                                                                        classNamePrefix={`${errorFields["model"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            (report.reportType === 'target' || report.reportType === 'column') ? (
                                                                <div className="form-group col-md-12">
                                                                    <label> Calculation Type </label>
                                                                    <ReactSelect
                                                                        options={calculateOptions}
                                                                        name={"calculationType"}
                                                                        placeholder={'select calculation type'}
                                                                        onChange={handleSelectChange}
                                                                        value={report.calculationType}
                                                                        classNamePrefix={`${errorFields["calculationType"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (report.reportType === 'target' || report.reportType === 'column') ? (
                                                                <div className="form-group col-md-12">
                                                                    <label>View by</label>
                                                                    <ReactSelect
                                                                        options={displayOptions}
                                                                        name={"displayBy"}
                                                                        placeholder={'select'}
                                                                        onChange={handleSelectChange}
                                                                        value={report.displayBy}
                                                                        classNamePrefix={`basic-select`}
                                                                        removeClearable={false}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (report.reportType === 'target' && (_.isEmpty(report.calculationType) || report.calculationType !== 'duration')) ?
                                                                (<><div className="form-group col-md-12">
                                                                    <label> Target </label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={`target`}
                                                                        className={`form-control ${errorFields["target"]}`}
                                                                        name="target"
                                                                        onChange={handleEditNumberChange}
                                                                        value={report.target}
                                                                    />
                                                                </div>
                                                                </>) : (<></>)
                                                        }
                                                        {
                                                            report.columnType === 'persistency' ? (<></>) : (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.columns && _.isArray(report.columns) && report.columns.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Columns'); setModelType('column'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    {
                                                                        report.reportType === 'table' ? (<label>Columns <span style={{ color: '#FF0000' }}>*</span></label>) :
                                                                            (<label>Measure by <span style={{ color: '#FF0000' }}>*</span></label>)
                                                                    }
                                                                    {
                                                                        report.groupBy === 'dataQuality' && report.displayBy === 'dataQuality' ? (
                                                                            <ReactMultiSelect
                                                                                options={reportColumns.filter(a =>
                                                                                    (report.calculationType === 'percentage' ? a.calculationType.indexOf('dqPercentage') >= 0 : true)
                                                                                    && (report.calculationType === 'duration' ? false : true)
                                                                                    && (report.calculationType === 'count' ? a.calculationType.indexOf('dqCount') >= 0 : true)
                                                                                )}
                                                                                name={"columns"}
                                                                                placeholder={`select columns`}
                                                                                onChange={(option) => handleReactMultiSelectChange(option, 'columns')}
                                                                                value={report.columns}
                                                                                classNamePrefix={`${errorFields["columns"]} basic-select`}
                                                                                isMulti={true}
                                                                                isSearchable={true}
                                                                            >
                                                                            </ReactMultiSelect>
                                                                        ) :
                                                                            report.reportType === 'pie' || report.groupBy === 'lostReasons' || report.displayBy === 'client' ? (
                                                                                <ReactSelect
                                                                                    options={report.groupBy === 'lostReasons' ? reportColumns.filter(a => a.value === 'total' && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)) : reportColumns.filter(a => a.view.indexOf(report.reportType) >= 0
                                                                                        //&& ((defaultGroup === 'user' || defaultGroup === 'dateFilter') ? true : (a.type !== 'activity' && a.type !== 'scheduleActivity' && a.type !== 'log'))
                                                                                        && (report.pipeline && report.pipeline.length === 1 && report.pipeline.some(e => e === 'LeadsBucket') ? (a.columnType === 'inbound' || _.isEmpty(a.columnType)) : (a.columnType === 'sales' || a.columnType === 'service' || _.isEmpty(a.columnType)))
                                                                                        && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)
                                                                                        && ((report.columnType === 'activities' || report.columnType === 'triggers') ? (a.type === 'activity' || a.type === 'scheduleActivity') : true)
                                                                                        && ((report.columnType === 'logs') ? (a.type === 'log') : true)
                                                                                        && ((report.columnType === 'duration') ? a.calculationType.indexOf('duration') >= 0 : true)
                                                                                        && ((report.columnType === 'performance') ? a.calculationType.indexOf('duration') < 0 : true)
                                                                                        && a.type !== 'creditScore'
                                                                                    )}
                                                                                    name={"columns"}
                                                                                    placeholder={`select columns`}
                                                                                    onChange={handleSelectChange}
                                                                                    value={_.isArray(report.columns) ? report.columns[0] : report.columns}
                                                                                    classNamePrefix={`${errorFields["columns"]} basic-select`}
                                                                                    isSearchable={true}
                                                                                >
                                                                                </ReactSelect>
                                                                            ) : (
                                                                                <ReactMultiSelect
                                                                                    options={reportColumns.filter(a => a.view.indexOf(report.reportType) >= 0
                                                                                        //&& ((defaultGroup === 'user' || defaultGroup === 'dateFilter') ? true : (a.type !== 'activity' && a.type !== 'scheduleActivity' && a.type !== 'log'))
                                                                                        && (report.pipeline && report.pipeline.length === 1 && report.pipeline.some(e => e === 'LeadsBucket') ? (a.columnType === 'inbound' || _.isEmpty(a.columnType)) : (a.columnType === 'sales' || a.columnType === 'service' || _.isEmpty(a.columnType)))
                                                                                        && ((!_.isEmpty(report.calculationType)) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)
                                                                                        && ((report.columnType === 'activities' || report.columnType === 'triggers') ? (a.type === 'activity' || a.type === 'scheduleActivity') : true)
                                                                                        && ((report.columnType === 'logs') ? (a.type === 'log') : true)
                                                                                        && ((report.columnType === 'duration') ? a.calculationType.indexOf('duration') >= 0 : true)
                                                                                        && ((report.columnType === 'performance') ? a.calculationType.indexOf('duration') < 0 : true)
                                                                                        && a.type !== 'creditScore'
                                                                                    )}
                                                                                    name={"columns"}
                                                                                    placeholder={`select columns`}
                                                                                    onChange={(option) => handleReactMultiSelectChange(option, 'columns')}
                                                                                    value={report.columns}
                                                                                    classNamePrefix={`${errorFields["columns"]} basic-select`}
                                                                                    isMulti={true}
                                                                                    isSearchable={true}
                                                                                >
                                                                                </ReactMultiSelect>
                                                                            )
                                                                    }

                                                                </div>)
                                                        }
                                                        {
                                                            (_.isArray(report.columns) && report?.columns?.length > 1 && report.reportType === 'table' && Boolean(props.dealersettings.superAdmin)) ? (
                                                                <div className="form-group col-md-12">
                                                                    <a href="#" onClick={() => {
                                                                        setCustomColumns({ title: 'Custom Columns', show: true })
                                                                    }} className="inputlink-addmore custom-more"><i className="ico icon-plus rearrange-mini"></i>add</a>
                                                                    <label> Custom Columns</label>
                                                                    <div style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setCustomColumns({ title: 'Custom Columns', show: true }); }}>
                                                                        <ReactMultiSelect
                                                                            options={report.dynColumns ? report.dynColumns.map(r => { return { label: r.name, value: r.id } }) : []}
                                                                            name={"dynColumns"}
                                                                            placeholder={`add columns`}
                                                                            onChange={(option) => handleReactMultiSelectChange(option, 'dynColumns')}
                                                                            value={report.dynColumns ? report.dynColumns.map(r => { return r.id }) : []}
                                                                            classNamePrefix={`basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                            isDisabled={true}
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (report.groupBy === 'user' || report.groupBy === 'enqAddedBy')
                                                                && _.isArray(report.columns)
                                                                && report.columns.some(a => a === 'testdrive' || a === 'tdtestdrive' || a === 'walkintestdrive' || a === 'appointmenttestdrive' || a === 'testdrivetotestdrive' || a === 'testdriveConv') ? (
                                                                <div className="form-group col-md-12">
                                                                    <label>Test Drive Count By</label>
                                                                    <ReactSelect
                                                                        options={testdriveOptions}
                                                                        name={"tdCountBy"}
                                                                        placeholder={`test drive count by`}
                                                                        onChange={handleSelectChange}
                                                                        value={report.tdCountBy}
                                                                        classNamePrefix={`${errorFields["tdCountBy"]} basic-select`}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            Boolean(props.dealersettings.superAdmin) ? (<>
                                                                <div className={`form-group col-md-${report.createdLevel === 'oem' || _.isEmpty(report.createdLevel) ? '12' : '6'}`}>
                                                                    <label> Create Report In</label>
                                                                    <ReactSelect
                                                                        options={levelOptions.filter(a => a.value !== 'individual')}
                                                                        name={"createdLevel"}
                                                                        placeholder={'select level'}
                                                                        onChange={handleSelectChange}
                                                                        value={report.createdLevel || 'oem'}
                                                                        classNamePrefix={`${errorFields["createdLevel"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                                {
                                                                    report.createdLevel ? (
                                                                        <div className={`form-group col-md-6`}>
                                                                            <label>&nbsp;</label>
                                                                            <ReactSelect
                                                                                options={regiongroupOptions}
                                                                                name={"createdLevelID"}
                                                                                placeholder={`select ${report.createdLevel}`}
                                                                                onChange={handleSelectChange}
                                                                                value={report.createdLevelID}
                                                                                classNamePrefix={`${errorFields["createdLevelID"]} basic-select`}
                                                                                removeClearable={true}
                                                                            >
                                                                            </ReactSelect>
                                                                        </div>
                                                                    ) : (<></>)
                                                                }
                                                            </>) : (<></>)
                                                        }
                                                        {/* <div className="form-group col-md-12">
                                                            {
                                                                report.filters && _.isArray(report.filters) && report.filters.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Filters'); setModelType('filter'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                            }
                                                            <label> Filters</label>
                                                            <ReactMultiSelect
                                                                options={reportFilters.filter(a => a.value !== 'triggers')}
                                                                name={"filters"}
                                                                placeholder={`select filters`}
                                                                onChange={(option) => handleReactMultiSelectChange(option, 'filters')}
                                                                value={report.filters}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div> */}
                                                        <div className="form-group col-md-12">
                                                            <label> Visibility </label>
                                                            <ReactSelect
                                                                options={visibilities}
                                                                name={"visibility"}
                                                                placeholder={'select visibility'}
                                                                onChange={handleSelectChange}
                                                                value={report.visibility}
                                                                classNamePrefix={`basic-select`}
                                                                removeClearable={true}
                                                                isSearchable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="trigger-form-footer">
                                                            <span className="switch switch-sm float-left">
                                                                <InputCheckBox
                                                                    className={`switch`}
                                                                    id={`report-includeZero`}
                                                                    name={'includeZero'}
                                                                    checked={report.includeZero}
                                                                    onChange={(e) => { handleCheckedChange(e) }} />
                                                                <label htmlFor={`report-includeZero`}>Include Zero Values</label>
                                                            </span>
                                                            <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { saveReport() }}>
                                                                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'save'} />
                                                            </button>
                                                            <button type="button" className="btn btn-default float-right" onClick={() => { handlePropsModelClose() }} > <Translate text={'cancel'} /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="settings-column-right">
                                                <div className="add-trigger-column-title">
                                                    <h3> <Translate text={'Report Preview'} />
                                                        <span className="pipeline-inactive-span"> (Sample Data)</span>
                                                    </h3>
                                                </div>
                                                <div className="trigger-form-wraper form-style">
                                                    {
                                                        reportData && reportData.length === 0 ? (
                                                            <div className="common-table">
                                                                <div className="text-center p-5">
                                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2"> No records found</div>
                                                                </div>
                                                            </div>
                                                        ) : report.reportType === 'table' ? (
                                                            <div className="common-table">
                                                                <TableView
                                                                    isReorderable={true}
                                                                    datalist={reportData}
                                                                    height={windowSize.tblHeight}
                                                                    width={windowSize.tblWidth}
                                                                    columns={reportFields}
                                                                    dynamicFields={reportFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                                    handleSavesettings={handleSavesettings}
                                                                />
                                                            </div>
                                                        ) : report.reportType === 'column' ?
                                                            (
                                                                <div className="text-center">
                                                                    <BarChart
                                                                        id={'report-bar-chart'}
                                                                        height={`${windowSize.tblHeight}px`}
                                                                        data={reportData}
                                                                        columns={report.displayBy === 'client' ? Object.keys(reportData[0]).filter(r => r.includes(`${report.groupBy}-`)) : report.columns ? _.isArray(report.columns) ? report.columns : [report.columns] : []}
                                                                        dynamicColumns={report.displayBy === 'client' ? dymamicCols : reportColumns}
                                                                        xCategory={'name'}
                                                                        calculationType={report.calculationType}
                                                                        handleShowEnquiryList={(value, startDate, endDate) => {
                                                                            // props.handleShowEnquiryList({
                                                                            //     'origin': value,
                                                                            //     'status': 'all',
                                                                            //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                            //     'startDate': startDate,
                                                                            //     'endDate': endDate,
                                                                            //     'dateType': 'addedDate'
                                                                            // })
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : report.reportType === 'pie' ?
                                                                (
                                                                    <div className="text-center">
                                                                        <PieChart
                                                                            id={'report-pie-chart'}
                                                                            height={`${windowSize.tblHeight}px`}
                                                                            data={reportData}
                                                                            columns={_.isArray(report.columns) ? report.columns[0] : report.columns}
                                                                            handleShowEnquiryList={(value, startDate, endDate) => {
                                                                                // props.handleShowEnquiryList({
                                                                                //     'origin': value,
                                                                                //     'status': 'all',
                                                                                //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                                //     'startDate': startDate,
                                                                                //     'endDate': endDate,
                                                                                //     'dateType': 'addedDate'
                                                                                // })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : report.reportType === 'target' ?
                                                                    (
                                                                        <div className="text-center">
                                                                            <LineChart
                                                                                id={'report-line-chart'}
                                                                                height={`${windowSize.tblHeight}px`}
                                                                                data={reportData}
                                                                                columns={report.target > 0 ? (_.isArray(report.columns) ? [...report.columns, 'target'] : [report.columns, 'target']) : (_.isArray(report.columns) ? report.columns : [report.columns])}
                                                                                dynamicColumns={reportColumns}
                                                                                calculationType={report.calculationType}
                                                                                handleShowEnquiryList={(value, startDate, endDate) => {
                                                                                    // props.handleShowEnquiryList({
                                                                                    //     'origin': value,
                                                                                    //     'status': 'all',
                                                                                    //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                                    //     'startDate': startDate,
                                                                                    //     'endDate': endDate,
                                                                                    //     'dateType': 'addedDate'
                                                                                    // })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (<></>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <PopUpModal show={showModel}>
                <RearrangeColumns
                    {...props}
                    show={showModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    type={modelType}
                    columns={report.columns}
                    filters={report.filters}
                    reportColumns={reportColumns}
                    report={report}
                    setColumns={(fields) => {
                        setReport({
                            ...report,
                            ['columns']: fields
                        })
                        setReportFields([
                            ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                                return {
                                    ...rec,
                                    default: true
                                }
                            }),
                            ...fields.map(rec => {
                                return {
                                    ..._.find(reportFields, { value: rec }),
                                    default: true
                                }
                            }),
                            ..._.filter(reportFields, (v) => _.indexOf(fields, v.value) < 0 && v.value !== 'name').map(rec => {
                                return {
                                    ...rec,
                                    default: false
                                }
                            }),
                        ])
                    }}
                    setFilters={(fields) => {
                        setReport({
                            ...report,
                            ['filters']: fields
                        })
                    }}
                    setReport={(fields, name) => {
                        setReport({
                            ...report,
                            [name]: fields
                        })
                    }}
                />
            </PopUpModal>
            <PopUpModal show={customColumns.show}>
                <CustomColumns
                    {...customColumns}
                    report={report}
                    handleClose={(columns, close) => {
                        if (!Boolean(close)) {
                            setReport({
                                ...report,
                                dynColumns: columns
                            })
                        }
                        setCustomColumns({ show: false })
                    }}
                    reportColumns={reportColumns}
                />
            </PopUpModal>
        </>
    )
}

export default AddDynamicReport